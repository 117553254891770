import InApp from 'detect-inapp';
import UserAgent from 'ua-parser-js';
import { v4 as uuid } from 'uuid';

// https://frontendmasters.com/blog/the-pitfalls-of-in-app-browsers/

const isInWebView = (): boolean => {
  const { isInApp } = new InApp(
    navigator.userAgent ||
      navigator.vendor ||
      (window as unknown as Record<string, string>).opera
  );
  return isInApp;
};

const APPLE_OPTIONS: string[] = ['iOS'];

const isApple = (): boolean => {
  const userAgent = new UserAgent();
  return APPLE_OPTIONS.includes(String(userAgent.getOS().name));
};

const ANDROID_OPTIONS: string[] = ['Android', 'Android-x86'];

const isAndroid = (): boolean => {
  const userAgent = new UserAgent();
  return ANDROID_OPTIONS.includes(String(userAgent.getOS().name));
};

const getEscapeHref = (href: string): string | null => {
  if (isApple()) {
    return `shortcuts://x-callback-url/run-shortcut?name=${uuid()}&x-error=${encodeURIComponent(href)}`;
  }
  if (isAndroid()) {
    return `intent:${href}#Intent;end`;
  }

  return null;
};

export const wrapWebViewEscape = (href: string): string => {
  if (isInWebView()) {
    return getEscapeHref(href) || href;
  }
  return href;
};
