import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';
import type { AuthLocationState } from '../types';

const locationToHref = (location?: Location): string | undefined => {
  if (!location) {
    return undefined;
  }
  return `${location.pathname}${location.search}`;
};

export const useFrom = (): string | undefined => {
  const { queryParams } = useQueryParams();
  const { state: locationState } = useLocation() as AuthLocationState;

  return queryParams.get('from') || locationToHref(locationState?.from);
};
