import { useState } from 'react';
import { createContext } from '@/utils';
import type { Category } from './types';

type Page = 'index' | 'add' | 'edit';

type CategorySelectContextValue = {
  page: Page;
  category: Category | null;
  addCategoryClick: () => void;
  editCategoryClick: (category: Category) => void;
  goBack: () => void;
};

type Props = {
  children: JSX.Element | ((value: CategorySelectContextValue) => JSX.Element);
};

export const CategorySelectContext = createContext<
  CategorySelectContextValue | undefined
>(undefined, 'CategorySelectContext');

export const CategorySelectContextProvider = ({ children }: Props) => {
  const [page, setPage] = useState<Page>('index');
  const [category, setCategory] = useState<Category | null>(null);

  const value: CategorySelectContextValue = {
    page,
    category,
    addCategoryClick: () => setPage('add'),
    editCategoryClick: (category: Category) => {
      setPage('edit');
      setCategory(category);
    },
    goBack: () => {
      setPage('index');
      setCategory(null);
    },
  };

  return (
    <CategorySelectContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </CategorySelectContext.Provider>
  );
};
