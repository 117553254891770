import { ErrorCodes } from '@/types/gql.generated';
import {
  getGQLErrorCode,
  getGQLErrorMessage,
  isErrorWithCode,
  isGQLError,
} from '@/utils/errors';
import { type ErrorInput, type PromptType, isFileType } from './types';

const errorCodeMessages: { [key in ErrorCodes]?: string } = {
  [ErrorCodes.PromptTimeout]: 'An unknown error occurred. Please try again.',
  [ErrorCodes.PromptResponseTooLong]:
    "Please make your prompt shorter or divide it into smaller parts; it's a bit too long to handle all at once!",
  [ErrorCodes.PromptRequestTooLong]:
    "Please make your prompt shorter or divide it into smaller parts; it's a bit too long to handle all at once!",
};

const defaultErrorMessage = 'An unknown error occurred. Please try again.';

type QuickCreateErrorMessage = {
  title: string;
  message?: string;
};

// `message` is not sanitized and should not accept values from errors
// originating outside our system
export const getErrorMessage = (
  error?: ErrorInput,
  type?: PromptType
): QuickCreateErrorMessage => {
  // Handle regular `Error` objects
  if (!isGQLError(error) && !isErrorWithCode(error)) {
    return {
      title: defaultErrorMessage,
    };
  }

  const code = isGQLError(error) ? getGQLErrorCode(error) : error.code;

  // PromptRequestInvalid is the only error code that can have a server-defined message
  if (isGQLError(error) && code === ErrorCodes.PromptRequestInvalid) {
    const serverMessage = getGQLErrorMessage(error);
    const hasServerDefinedMessage = serverMessage && serverMessage !== code;

    if (hasServerDefinedMessage) {
      return {
        title: serverMessage,
      };
    }
  }

  // Handle image prompts that throw NoContent. Only image prompts can throw
  // this error; text prompts fallback to single event
  if (isFileType(type) && code === ErrorCodes.NoContent) {
    return {
      title: "We didn't find any calendar events in your image 🙈",
    };
  }

  // Handle prompt parse errors
  if (
    code === ErrorCodes.PromptResponseParseError ||
    code === ErrorCodes.PromptRequestInvalid ||
    code === ErrorCodes.NoContent ||
    code === ErrorCodes.NoResponse
  ) {
    return {
      title: 'Our magical powers need a little more to go on... 🎩✨',
      message: '<strong>Tip!</strong> Include a date in your prompt',
    };
  }

  // Handle other error codes for which we defined a default message
  if (code in errorCodeMessages) {
    return {
      title: errorCodeMessages[code] ?? defaultErrorMessage,
    };
  }

  return {
    title: defaultErrorMessage,
  };
};
