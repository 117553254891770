import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { getScheduleSlug } from '@/utils/schedule';

export const useSyncScheduleSlug = () => {
  const { schedule, isLoading } = useScheduleContext();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!schedule) {
      // ignore if this fires before the schedule exists
      return;
    }

    const title = schedule.title || t('untitled_schedule');
    const expectedSlug = getScheduleSlug({ id: schedule.id, title });

    if (!location.pathname.includes(expectedSlug)) {
      const parts = location.pathname.split('/');
      parts[2] = expectedSlug;
      navigate(parts.join('/') + location.search, {
        ...location,
        replace: true,
      });
    }
  }, [isLoading, schedule, location, navigate, t]);
};
