import { JobStatus } from '@/types/gql.generated';

type JobLike = {
  status: JobStatus;
};

export const isJobInProgress = (job: JobLike) => {
  return [JobStatus.Active, JobStatus.Waiting, JobStatus.Delayed].includes(
    job.status
  );
};
