import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverActionButton } from '@/pages/Schedule/components/Popover';
import { useToast, Button, Icons } from '@/ui';
import { isGQLErrorOfType } from '@/utils/errors';
import {
  getSnippetsInUse,
  useSnippetIngredientInUseModal,
} from '../../SnippetIngredientInUseModal';
import { useCategorySelectContext } from '../hooks/useCategorySelectContext';
import { useRemoveCategory } from '../hooks/useRemoveCategory';
import type { Category } from '../types';

const BackButton = () => {
  const { goBack } = useCategorySelectContext();
  const { t } = useTranslation('categorySelect');

  return (
    <PopoverActionButton
      Icon={Icons.ArrowLeft}
      label={t('back_label')}
      onClick={goBack}
    />
  );
};

const AddButton = () => {
  const { addCategoryClick } = useCategorySelectContext();
  const { t } = useTranslation('categorySelect');

  return (
    <PopoverActionButton
      Icon={Icons.Plus}
      label={t('add_category_label')}
      onClick={addCategoryClick}
    />
  );
};

const ExitAddButton = () => {
  const { goBack } = useCategorySelectContext();
  const { t } = useTranslation('categorySelect');

  return (
    <PopoverActionButton
      Icon={Icons.Trash}
      label={t('cancel_label')}
      onClick={goBack}
    />
  );
};

type ButtonProps = {
  selected?: Category | null;
  onSelect: (category: Category | null) => void;
};

const RemoveButton = ({ selected, onSelect }: ButtonProps) => {
  const { t } = useTranslation('categorySelect');
  const toast = useToast();
  const { category, goBack } = useCategorySelectContext();
  const { removeCategory } = useRemoveCategory();
  const { open: openSnippetIngredientInUseModal } =
    useSnippetIngredientInUseModal();
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    const timerId = isConfirming
      ? setTimeout(() => setIsConfirming(false), 3000)
      : undefined;
    return () => clearTimeout(timerId);
  }, [isConfirming]);

  if (!category) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      {isConfirming ? (
        <Button
          animate={{ opacity: 1 }}
          as={motion.button}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          size="xs"
          onClick={() => {
            if (selected?.id === category?.id) {
              onSelect(null);
            }
            removeCategory(category?.id, {
              onSuccess: () => {
                toast.notify(t('remove_success'));
                goBack();
              },
              onError: (err) => {
                onSelect?.(category);
                if (isGQLErrorOfType(err, 'CategoryInUse')) {
                  const snippets = getSnippetsInUse(err);
                  openSnippetIngredientInUseModal('category', snippets);
                } else {
                  toast.error(t('delete_error'));
                }
              },
            });
          }}
        >
          {t('delete_confirm')}
        </Button>
      ) : (
        <PopoverActionButton
          Icon={Icons.Trash}
          animate={{ opacity: 1 }}
          as={motion.button}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          label={t('remove_label')}
          onClick={() => setIsConfirming(true)}
        />
      )}
    </AnimatePresence>
  );
};

export const PrimaryActionButton = ({ selected, onSelect }: ButtonProps) => {
  const { page } = useCategorySelectContext();

  if (page === 'add') {
    return <ExitAddButton />;
  }

  if (page === 'edit') {
    return <RemoveButton selected={selected} onSelect={onSelect} />;
  }

  return <AddButton />;
};

export const SecondaryActionButton = () => {
  const { page } = useCategorySelectContext();

  if (page === 'add') {
    return <BackButton />;
  }

  if (page === 'edit') {
    return <BackButton />;
  }

  return null;
};

export const Title = () => {
  const { t } = useTranslation('categorySelect');
  const { page } = useCategorySelectContext();

  if (page === 'add') {
    return <span>{t('new_category')}</span>;
  }

  if (page === 'edit') {
    return <span>{t('edit')}</span>;
  }

  return <span>{t('heading')}</span>;
};
