import { Frequency } from 'rrule';
import { i18n } from '@/i18n';

type RecurrenceOption = {
  label: string;
  rule: {
    freq: Frequency | null;
    interval: number | null;
  };
};

export const RecurrenceOptions = [
  {
    label: i18n.t('common:recurrence_select.once'),
    rule: {
      freq: null,
      interval: null,
    },
  },
  {
    label: i18n.t('common:recurrence_select.daily'),
    rule: {
      freq: Frequency.DAILY,
      interval: 1,
    },
  },
  {
    label: i18n.t('common:recurrence_select.weekly'),
    rule: {
      freq: Frequency.WEEKLY,
      interval: 1,
    },
  },
  {
    label: i18n.t('common:recurrence_select.biweekly'),
    rule: {
      freq: Frequency.WEEKLY,
      interval: 2,
    },
  },
  {
    label: i18n.t('common:recurrence_select.monthly'),
    rule: {
      freq: Frequency.MONTHLY,
      interval: 1,
    },
  },
  {
    label: i18n.t('common:recurrence_select.yearly'),
    rule: {
      freq: Frequency.YEARLY,
      interval: 1,
    },
  },
] as const satisfies readonly RecurrenceOption[];
