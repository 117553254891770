import { gql } from 'graphql-request';

export const QuickEntryFragment = gql`
  fragment QuickEntry on QuickEntry {
    id
    title
    emoji
    description
    timeZone
    locationWithPlace {
      name
      googlePlaceId
    }
    recurrences {
      startDate
      endDate
      isOnDay
      rule
    }
  }
`;
