import { lazy } from 'react';
import { i18n } from '@/i18n';
import { AgendaModule } from '../AgendaView';
import { createViewModule } from '../utils';
import {
  adjustEndDate,
  adjustStartDate,
  adjustNextStartDate,
  adjustPreviousStartDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
} from './handlers';
import WeekIcon from './week.svg?react';

const WeekPicker = lazy(
  () => import('@/pages/Schedule/DateRangeNavigation/WeekPicker')
);

export const WeekModule = createViewModule({
  id: 'week',
  DateNavigationComponent: WeekPicker,
  mobileViewModule: AgendaModule,
  adjustStartDate,
  adjustEndDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
  adjustPreviousStartDate,
  adjustNextStartDate,
  viewSelect: {
    label: i18n.t('actionBar:view_select.week'),
    Icon: WeekIcon,
  },
});
