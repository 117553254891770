import { useState } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useToast, Container, Flex } from '@/ui';
import { useSchedulePermissions } from '../../hooks';
import { useOpenScheduleHeaderModal } from '../ScheduleHeaderModal';
import { AddHeaderButton } from './AddHeaderButton';
import { CustomCta } from './CustomCta';
import { ScheduleTitle } from './ScheduleTitle';
import { useEnableScheduleHeader } from './useEnableScheduleHeader';

export const ScheduleHero = () => {
  const { schedule } = useScheduleContext();
  const [isEditingTitle, setEditingTitle] = useState(false);
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { hasSchedulePermission } = useSchedulePermissions();
  const canAddBanner = hasSchedulePermission('SCHEDULE_MODIFY_SETTINGS');
  const canRenameSchedule = hasSchedulePermission('SCHEDULE_RENAME');
  const { enableScheduleHeader } = useEnableScheduleHeader();
  const { open: openHeaderModal } = useOpenScheduleHeaderModal();
  const toast = useToast();
  const { trackEvent } = useAnalytics();

  const handleAddBannerClick = () => {
    enableScheduleHeader({
      onError: () => toast.error('Error enabling schedule banner'),
    });
    openHeaderModal();
    trackEvent('schedule:click add header');
  };

  return (
    <Container
      bg="white"
      borderTopRadius={schedule?.header.enabled ? 'none' : { xl: 'xl' }}
      pos="relative"
      pt={{ base: 3, md: 6 }}
      variant="max"
    >
      <Flex
        align="center"
        gap={{ base: 2, md: 6 }}
        justify="space-between"
        {...(canRenameSchedule && {
          ml: -3,
          w: isEditingTitle
            ? 'calc(100% + (var(--chakra-space-3) * 2))'
            : undefined,
        })}
      >
        <ScheduleTitle
          flex="1"
          isEditing={isEditingTitle}
          maxW="100%"
          overflow={isEditingTitle ? 'visible' : 'hidden'}
          onEditChange={(value) => setEditingTitle(value)}
        />

        {canAddBanner &&
        schedule?.header.enabled === false &&
        !isEditingTitle ? (
          <AddHeaderButton onClick={handleAddBannerClick} />
        ) : !isMobileBreakpoint ? (
          <CustomCta />
        ) : null}
      </Flex>

      {isMobileBreakpoint && <CustomCta mb="1" mt="3" />}
    </Container>
  );
};
