import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text } from '@/ui';
import { useAddContentModalStore } from '../store';
import { Confetti } from './Confetti';

export const CreateScheduleHeader = () => {
  const { t } = useTranslation('addContent', {
    keyPrefix: 'add_content_header',
  });
  const { config } = useAddContentModalStore();

  return (
    <Flex
      align="center"
      direction="column"
      flex="1"
      fontSize="lg"
      fontWeight="medium"
      justify="center"
      mb="4"
      mt="12"
      textAlign="center"
    >
      <Text fontSize="44px" letterSpacing="12px" lineHeight="1">
        {config.headerIcons || '🎉🎉🎉'}
      </Text>
      <Confetti mx="auto" />
      <Text fontSize="3xl" fontWeight="bold" mb="3" mt="2">
        {t('title')}
      </Text>
      <Text maxW={{ base: '100%', md: 'none' }}>{t('byline')}</Text>
      <Text maxW={{ base: '90%', md: 'none' }} mt={{ base: 2, sm: 0 }}>
        <Trans i18nKey="tip" t={t} />
      </Text>
    </Flex>
  );
};
