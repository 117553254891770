import { useRef } from 'react';
import { useRemoveIntegration } from '@/hooks/useRemoveIntegration';
import {
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from '@/ui';
import { getScheduleSourceCount } from '@/utils/googleCalendar';
import { useDisconnectDialog } from './store';

export const DisconnectDialog = () => {
  const toast = useToast();
  const { removeIntegration, isPending } = useRemoveIntegration();
  const { isOpen, close, integration } = useDisconnectDialog();
  const cancelRef = useRef(null);

  const handleRemove = () => {
    if (!integration) {
      return;
    }

    removeIntegration(integration.id, {
      onError: () =>
        toast.error(
          'Something went wrong while disconnecting this account. Please try again.'
        ),
      onSuccess: () => {
        close();
        toast.notify(
          `The ${integration.name} Google account has been disconnected from Agenda Hero. We will no longer be able to access calendars or events in this account`
        );
      },
    });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={close}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>Disconnect Integration</AlertDialogHeader>
          <AlertDialogBody display="flex" flexDir="column" gap="4">
            <Text>
              Are you sure you want to remove the Google integration{' '}
              <Text as="span" fontWeight="bold">
                {integration?.name}
              </Text>{' '}
              from your Agenda Hero account?
            </Text>
            {integration && getScheduleSourceCount(integration) > 0 && (
              <Text>
                <Text as="span" fontWeight="bold">
                  Warning:{' '}
                </Text>
                If you remove the integration, events from its Google Calendars
                will no longer be available in any of your Agenda Hero
                schedules.
              </Text>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="dark"
              ref={cancelRef}
              variant="ghost"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isLoading={isPending}
              ml={2}
              variant="ghost"
              onClick={handleRemove}
            >
              Disconnect
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
