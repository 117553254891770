export const notionVariantBaseStyles = {
  color: 'customgray.dark',
  fontWeight: 'normal',
  fontSize: 'md',
  borderRadius: 4,
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '4px',
  paddingRight: '4px',
  height: 'auto',
  minHeight: 9,
  _hover: {
    bg: 'gray.100',
  },
  _disabled: {
    _hover: {
      bg: 'inherit',
    },
  },
};
