import { DateTime } from 'luxon';

/**
 * react-dates uses regular `Date` objects which are pinned to the time and
 * zone of the computer that the code is executed on. There is no concept
 * of "initialize a date in a particular time zone".
 *
 * Therefore, when we take an item's `startTime` which is a `DateTime` in the
 * schedule's zone and convert it to a `Date` object for consumption by
 * react-datepicker, the date part can shift back/forwards a day based on the time
 * part because the `Date` object now represents the underlying timestamp in the
 * user's local zone. We need the `Date` to keep reflecting the schedule's zone.
 *
 * To workaround this, we initialize a new `Date` object in the user's local zone
 * using the get the YYYY-MM-dd part of the date in the schedule's zone to
 * ensure that the time part doesn't influence the date part.
 *
 * See:
 * https://github.com/Hacker0x01/react-datepicker/issues/1787
 * https://stackoverflow.com/questions/53052012/set-timezone-react-datepicker
 */
export const dateInLocalZone = (date: DateTime): Date => {
  const datePart = date.toISODate();
  return DateTime.fromISO(datePart).toJSDate();
};

export const dateInScheduleZone = (date: Date, timeZone: string): Date => {
  const dateWithoutZone = DateTime.fromJSDate(date).toISO({
    includeOffset: false,
  });
  const dateInScheduleZone = DateTime.fromJSDate(date, {
    zone: timeZone,
  }).toFormat('ZZZ');
  const dateWithScheduleZone = `${dateWithoutZone}${dateInScheduleZone}`;

  return new Date(dateWithScheduleZone);
};
