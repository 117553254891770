import {
  hashKey,
  MutationCache,
  QueryCache,
  QueryClient,
  type QueryKey,
} from '@tanstack/react-query';
import { shouldRedirectOnLogout } from '@/components/auth';
import { isGQLError, isGQLErrorOfType, type QueryError } from '@/utils/errors';
import {
  createProfileQueryKey,
  createSnippetQueryKey,
} from '@/utils/queryKeys';
import { Sentry } from './sentry';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: QueryError;
  }
}

const errorHandler = (error: unknown, queryKey?: QueryKey) => {
  const isGraphQLError = isGQLError(error);

  const data = {
    extra: {
      queryKey,
    },
  };

  if (isGraphQLError && error.response.status >= 500) {
    const wrappedErr = new Error(
      `${error.response.status} error received from the GraphQL API`
    );
    wrappedErr.cause = error;
    Sentry.captureException(wrappedErr, data);
  } else if (!isGraphQLError || isGQLErrorOfType(error, 'UnknownError')) {
    Sentry.captureException(error, data);
  }

  // If this is a 401 error...
  if (isGQLError(error) && isGQLErrorOfType(error, 'NotLoggedIn')) {
    // The snippet page handles 401 errors itself so ignore this query
    const snippetQueryKey = createSnippetQueryKey();
    const isSnippetQuery = queryKey && queryKey[0] === snippetQueryKey[0];

    // The profile query expectedly returns 401 if logged out
    const isProfileQuery =
      queryKey && hashKey(queryKey) === hashKey(createProfileQueryKey());

    // The presence of queryHash indicates a query vs mutation
    if (!queryKey || (!isSnippetQuery && !isProfileQuery)) {
      const { shouldRedirect, path } = shouldRedirectOnLogout();
      if (shouldRedirect) {
        window.location.href = path;
      }
    }
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Don't automatically retry GQL errors
      retry: (_, err) => !isGQLError(err),
    },
    mutations: {
      // Don't automatically retry GQL errors
      retry: (_, err) => !isGQLError(err),
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => errorHandler(error),
  }),
  queryCache: new QueryCache({
    onError: (error, { queryKey }) => errorHandler(error, queryKey),
  }),
});
