// TODO Move this to getProfile.config on server

export const COLORS = [
  {
    sortOrder: 0,
    color: '#E62350',
  },
  {
    sortOrder: 1,
    color: '#EF6C2A',
  },
  {
    sortOrder: 2,
    color: '#F8C83A',
  },
  {
    sortOrder: 3,
    color: '#543CDE',
  },
  {
    sortOrder: 4,
    color: '#2C88D9',
  },
  {
    sortOrder: 5,
    color: '#207868',
  },
  {
    sortOrder: 6,
    color: '#BC9055',
  },
  {
    sortOrder: 7,
    color: '#212121',
  },
];
