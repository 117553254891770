import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const bootstrappedTitle = document.title;

export const usePageTitle = (title?: string | null) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!title && bootstrappedTitle) {
      // keep using the bootstrapped title if it exists and the title
      // prop is not set. this scenario probably means that the page
      // is still loading its data and a title will be set later.
      return;
    }

    const suffix = t('page_title_suffix');
    const titleWithSuffix = title ? `${title} | ${suffix}` : suffix;
    const prevTitle = document.title;

    document.title = titleWithSuffix;

    return () => {
      if (prevTitle && prevTitle !== bootstrappedTitle) {
        document.title = prevTitle;
      }
    };
  }, [title, t]);
};
