import { useTranslation } from 'react-i18next';
import {
  PopoverActionButton,
  PopoverPanel,
} from '@/pages/Schedule/components/Popover';
import type { LabelType, Label } from '@/types/gql.generated';
import { useToast, Box, Icons, useControllableState } from '@/ui';
import { definitions as labelDefinitions } from '../../utils/labelDefinitions';
import { AddLabelInput } from './components/AddLabelInput';
import { EmptyCta } from './components/EmptyCta';
import { LabelList } from './components/LabelList';
import { useCreateLabel } from './hooks/useCreateLabel';
import { useOptimisticLabels } from './hooks/useOptimisticLabels';
import { useOptimisticSelected } from './hooks/useOptimisticSelected';
import { createDraftLabel } from './utils';

type Props = {
  labelType: LabelType;
  selected?: Label[];
  isAdding?: boolean;
  onSelect: (label: Label) => void;
  onDeselect: (label: Label) => void;
  onAddingChange?: (isAdding: boolean) => void;
  onUpdate?: (label: Label) => void;
};

export const LabelSelect = ({
  labelType,
  selected,
  isAdding,
  onSelect,
  onDeselect,
  onAddingChange,
  onUpdate,
}: Props) => {
  const lib = labelDefinitions[labelType];
  const toast = useToast();
  const { createLabel } = useCreateLabel(labelType);
  const { t } = useTranslation('labelSelect');

  const { labels, setLabels } = useOptimisticLabels(labelType);
  const {
    optimisticSelected,
    addOptimisticSelected,
    removeOptimisticSelected,
  } = useOptimisticSelected(selected ?? []);

  const [isAddOpen, setAddOpen] = useControllableState(
    onAddingChange
      ? { value: isAdding, onChange: onAddingChange }
      : { defaultValue: false }
  );

  const handleUpdateSortOrder = (labels: Label[]) => {
    setLabels(labels);
  };

  const handleUpdate = (updatedLabel: Label) => {
    setLabels((labels) =>
      labels.map((label) =>
        label.id === updatedLabel.id ? updatedLabel : label
      )
    );
    onUpdate?.(updatedLabel);
  };

  const handleDelete = (deletedLabel: Label) => {
    setLabels((labels) =>
      labels.filter((label) => label.id !== deletedLabel.id)
    );
  };

  const handleCreate = (labelText: string) => {
    const duplicateLabel = labels.find(
      (label) => label.text.toLowerCase() === labelText.toLowerCase()
    );

    const isDuplicateSelected =
      duplicateLabel &&
      optimisticSelected.find((label) => label.id === duplicateLabel.id);

    if (!duplicateLabel) {
      const label = createDraftLabel(labelText, labels.length);
      setLabels((labels) => [...labels, label]);
      setAddOpen(false);
      addOptimisticSelected(label);
      createLabel(label, {
        onSuccess: () => {
          // The label can't be selected by the consumer until it exists in the db.
          // onSelect may trigger an API call to add the label to a feed, for example
          onSelect(label);
        },
        onError: () => {
          toast.error(t('toasts.create_error'));
          removeOptimisticSelected(label);
        },
      });
    } else if (!isDuplicateSelected) {
      onSelect(duplicateLabel);
      addOptimisticSelected(duplicateLabel);
      setAddOpen(false);
    } else {
      setAddOpen(false);
    }
  };

  const handleSelect = (label: Label) => {
    addOptimisticSelected(label);
    onSelect(label);
  };

  const handleDeselect = (label: Label) => {
    removeOptimisticSelected(label);
    onDeselect(label);
  };

  return (
    <PopoverPanel
      title={lib.title}
      action={
        <PopoverActionButton
          Icon={Icons.Plus}
          label={t('create')}
          onClick={() => setAddOpen(true)}
        />
      }
    >
      {labels.length > 0 ? (
        <Box pb={isAddOpen ? '0' : '2'} pt="2" px="1">
          <LabelList
            labelType={labelType}
            labels={labels}
            selected={optimisticSelected}
            onDelete={handleDelete}
            onDeselect={handleDeselect}
            onSelect={handleSelect}
            onUpdate={handleUpdate}
            onUpdateSortOrder={handleUpdateSortOrder}
          />
        </Box>
      ) : !isAddOpen ? (
        <EmptyCta prompt={lib.prompt} />
      ) : null}

      {isAddOpen && (
        <Box
          pb="2"
          pl={labels.length > 0 ? '8' : '4'}
          pt={labels.length > 0 ? '1' : '3'}
        >
          <AddLabelInput
            onAdd={handleCreate}
            onCancel={() => setAddOpen(false)}
          />
        </Box>
      )}
    </PopoverPanel>
  );
};
