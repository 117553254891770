// If a 401 occurs on one of these routes then don't redirect to /auth.
// Users are allowed to be on these routes unauthenticated.
const NO_REDIRECT_ALLOW_LIST = ['/magic', '/magic-extension', '/dashboard'];

type Return = {
  shouldRedirect: boolean;
  path: string;
};

export const shouldRedirectOnLogout = (): Return => {
  const shouldRedirect = !NO_REDIRECT_ALLOW_LIST.some((allowedPath) =>
    window.location.pathname.startsWith(allowedPath)
  );
  return {
    shouldRedirect,
    path: '/auth',
  };
};
