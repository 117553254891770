import { gql } from 'graphql-request';
import { EntryFragment } from '@/fragments';

export const EntriesContextFragment = gql`
  ${EntryFragment}
  fragment EntriesContextFragment on Entry {
    ...Entry
    messages @include(if: $includeMessages) {
      id
      body
      status
      instance
      deliveryTimeType
      hybridDeliveryTime
      hybridRelativeTime
      hybridTimeZone
      relativeTimeToEntryStart
      relativeTimeToEntryStartUnit
      relativeTimeToEntryStartDirection
      recipients {
        id
        name
      }
    }
    rsvpInfo @include(if: $includeRsvps) {
      status
      notes
    }
  }
`;
