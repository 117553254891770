import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  useScheduleContext,
  useScheduleFilters,
} from '@/pages/Schedule/contexts';
import {
  useToast,
  FormControl,
  Input,
  FormLabel,
  Button,
  Flex,
  FormErrorMessage,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Icon,
  IconButton,
  Box,
  Divider,
  Text,
  PopoverHeader,
} from '@/ui';
import { useCreateSnippet } from './hooks/useCreateSnippet';

export const CreateSnippetPopover = () => {
  const {
    createSnippet,
    reset: resetMutation,
    data,
    isPending,
    isSuccess,
  } = useCreateSnippet();
  const { t } = useTranslation('createSnippetPopover');
  const { filterIds } = useScheduleFilters();
  const { scheduleId } = useScheduleContext();
  const { error: errorToast } = useToast();
  const navigate = useNavigate();
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { isOpen, onToggle: toggle, onClose: close } = useDisclosure();
  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const handleClose = () => {
    resetForm();
    resetMutation();
    close();
  };

  const handleNavigateToSnippet = () => {
    navigate(`/snippet/${data?.createSnippet?.id}`, {
      state: { scheduleId },
    });
  };

  const { ref, ...registerProps } = register('name', {
    required: t('validation_error'),
  });

  return (
    <Popover
      initialFocusRef={nameInputRef}
      isLazy
      isOpen={isOpen}
      offset={isMobileBreakpoint ? [16, 8] : undefined}
      placement="bottom-end"
      returnFocusOnClose={false}
      variant="primary"
      onClose={handleClose}
    >
      <PopoverTrigger>
        {isMobileBreakpoint ? (
          <IconButton
            aria-label={t('toggle_button_text')}
            icon={<Icon icon="Scissors" />}
            isActive={isOpen}
            variant="outline"
            onClick={toggle}
          />
        ) : (
          <Button
            data-userguiding-trigger="create-snippet-button"
            flexShrink="0"
            fontSize="sm"
            isActive={isOpen}
            leftIcon={<Icon icon="Scissors" />}
            variant="outline"
            onClick={toggle}
          >
            <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {t('toggle_button_text')}
            </Box>
          </Button>
        )}
      </PopoverTrigger>

      <PopoverContent shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.1)">
        <PopoverHeader
          display={{ md: 'none' }}
          fontSize="lg"
          fontWeight="medium"
          pl="5"
        >
          {t('header')}
        </PopoverHeader>
        <PopoverBody p="0">
          <Box p="5">
            <form
              onSubmit={handleSubmit((values) => {
                createSnippet(
                  {
                    ...values,
                    categories: filterIds.categories,
                    labels: filterIds.labels.map((id) => ({ id })),
                    whoLabels: filterIds.whoLabels.map((id) => ({ id })),
                    feeds: filterIds.feeds,
                  },
                  {
                    onSuccess: () => trackEvent('snippet:create'),
                    onError: () => errorToast(t('create_error')),
                  }
                );
              })}
            >
              <FormControl isInvalid={!!errors.name}>
                <FormLabel fontWeight="medium">{t('label')}</FormLabel>
                <Input
                  autoFocus
                  placeholder={t('placeholder')}
                  type="text"
                  {...registerProps}
                  ref={(el) => {
                    ref(el);
                    nameInputRef.current = el;
                  }}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <Flex align="center" mt="3">
                <Button
                  bg={isSuccess ? 'gray.100' : undefined}
                  color={isSuccess ? 'customgray.dark' : undefined}
                  isDisabled={isSuccess}
                  isLoading={isPending}
                  opacity={isSuccess ? '1 !important' : undefined}
                  size="sm"
                  type="submit"
                  _hover={
                    isSuccess
                      ? {
                          color: 'customgray.dark',
                          bg: 'gray.100',
                        }
                      : undefined
                  }
                >
                  {isSuccess ? 'Created!' : 'Create'}
                </Button>

                {isSuccess ? (
                  <Button
                    autoFocus // fixes a bug where the popover won't close after creating a snippet
                    ml="2"
                    rightIcon={<Icon icon="ArrowRight" />}
                    size="sm"
                    variant="link"
                    onClick={handleNavigateToSnippet}
                  >
                    {t('view_button')}
                  </Button>
                ) : (
                  <Button
                    ml="1"
                    size="sm"
                    variant="ghost"
                    onClick={handleClose}
                  >
                    {t('cancel_button')}
                  </Button>
                )}
              </Flex>
            </form>
          </Box>

          <Divider />

          <Box fontSize="sm" p="5">
            <Text fontWeight="medium">{t('help.title')}</Text>
            <Text color="customgray.mid" lineHeight="normal" mt="2">
              {t('help.text')}
            </Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
