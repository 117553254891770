import { useEffect } from 'react';
import { useAnalytics } from './useAnalytics';

type TrackPageView = ReturnType<typeof useAnalytics>['trackPageView'];

export const useTrackPageView: TrackPageView = (page, properties) => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(page, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
