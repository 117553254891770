import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { logger } from '@/lib/logger';
import { QuickEntriesJobVersion } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  CreateQuickEntriesJobFromFileMutation,
  CreateQuickEntriesJobFromFileMutationVariables,
} from './useCreateQuickEntriesJobFromFile.generated';

const query = gql`
  mutation createQuickEntriesJobFromFile(
    $file: Upload
    $base64File: Base64FileInput
    $timeZone: TimeZone!
    $instructions: String
    $version: QuickEntriesJobVersion!
  ) {
    createQuickEntriesJobFromFile(
      file: $file
      base64File: $base64File
      timeZone: $timeZone
      instructions: $instructions
      version: $version
    ) {
      id
    }
  }
`;

export const useCreateQuickEntriesJobFromFile = () => {
  return useMutation<
    string,
    QueryError,
    Omit<CreateQuickEntriesJobFromFileMutationVariables, 'version'>
  >({
    mutationFn: async (variables) => {
      logger.info('Calling createQuickEntriesJobFromFile mutation', {
        variables,
      });

      const result = await gqlClient.request<
        CreateQuickEntriesJobFromFileMutation,
        CreateQuickEntriesJobFromFileMutationVariables
      >(query, {
        ...variables,
        version: QuickEntriesJobVersion.V2,
      });

      return result.createQuickEntriesJobFromFile.id;
    },
  });
};
