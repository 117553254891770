import { useQuery, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { ScheduleTemplate as FullScheduleTemplate } from '@/types/gql.generated';
import { createScheduleTemplatesQueryKey } from '@/utils/queryKeys';
import type {
  GetScheduleTemplatesQuery,
  GetScheduleTemplatesQueryVariables,
} from './useScheduleTemplates.generated';

export type ScheduleTemplate = Omit<
  FullScheduleTemplate,
  'headerId' | 'categoryDefaults'
>;

const query = gql`
  query GetScheduleTemplates {
    getScheduleTemplates {
      id
      name
      headerIcons
      title
      headerBaseUrl
    }
  }
`;

const selector = (data: GetScheduleTemplatesQuery) => data.getScheduleTemplates;

export const useScheduleTemplates = () => {
  const queryClient = useQueryClient();
  const {
    data = [],
    isPending,
    error,
  } = useQuery({
    queryKey: createScheduleTemplatesQueryKey(),
    select: selector,
    queryFn: () =>
      gqlClient.request<
        GetScheduleTemplatesQuery,
        GetScheduleTemplatesQueryVariables
      >(query),
  });

  const fetchQuery = async () => {
    const result = await queryClient.fetchQuery({
      queryKey: createScheduleTemplatesQueryKey(),
      queryFn: () =>
        gqlClient.request<
          GetScheduleTemplatesQuery,
          GetScheduleTemplatesQueryVariables
        >(query),
    });
    return selector(result);
  };

  return {
    templates: data,
    isLoading: isPending,
    error,
    fetchQuery,
  };
};
