import { ChevronDownIcon } from '@chakra-ui/icons';
import { BsPinFill } from 'react-icons/bs';
import { PlatformIcon } from '@/components/PlatformIcon';
import { useCurrentUserContext } from '@/contexts';
import { useSetPreferredPlatform } from '@/hooks/useSetPreferredPlatform';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import {
  useToast,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@/ui';
import { ensurePlatform, getName } from '@/utils/platformLib';

const PLATFORMS = [
  PlatformTypes.GoogleIntegration,
  PlatformTypes.AppleIcs,
  PlatformTypes.OutlookLink,
  PlatformTypes.OfficeLink,
  PlatformTypes.OtherIcs,
];

export const PlatformPicker = () => {
  const { currentUser } = useCurrentUserContext();
  const { data: integrations = [] } = useIntegrations();
  const { setPreferredPlatform } = useSetPreferredPlatform();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const selectedPlatform = currentUser?.preferredPlatform
    ? ensurePlatform(currentUser.preferredPlatform, integrations)
    : null;

  const handleChange = (platform: PlatformTypes) => {
    setPreferredPlatform(platform, {
      onSuccess: () => onClose(),
      onError: () => {
        toast.error(
          'Whoops! We had some trouble setting your preferred Magic calendar platform. Please try again.'
        );
      },
    });
  };

  return (
    <Menu isOpen={isOpen} matchWidth onClose={onClose} onOpen={onOpen}>
      <MenuButton
        bg="white"
        borderRadius="md"
        borderWidth="1px"
        flexGrow="1"
        overflow="hidden"
        pl="3"
        pr="2"
        py="2"
        type="button"
        w="100%"
      >
        <Flex align="center" as="span" justify="space-between">
          <Flex
            align="center"
            as="span"
            flexShrink="1"
            gap="3"
            justify="flex-start"
            minW="0"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {selectedPlatform && <PlatformIcon platform={selectedPlatform} />}
            <Text as="span">
              {selectedPlatform ? getName(selectedPlatform) : 'Select platform'}
            </Text>
          </Flex>
          <ChevronDownIcon boxSize="20px" />
        </Flex>
      </MenuButton>
      <MenuList zIndex="4">
        {PLATFORMS.map((platformType) => {
          return (
            <MenuItem
              icon={<PlatformIcon platform={platformType} />}
              key={platformType}
              onClick={() => handleChange(platformType)}
            >
              <Flex align="center" as="span" justify="space-between">
                <Text as="span">{getName(platformType)}</Text>
                {platformType === selectedPlatform && (
                  <Icon as={BsPinFill} color="purple.600" />
                )}
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
