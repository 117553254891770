import { useEffect, useRef } from 'react';

export const useStickyClassName = (className: string) => {
  const ref = useRef(null);

  useEffect(() => {
    const elem = ref.current;
    const observer = new IntersectionObserver(
      ([elem]) =>
        elem.target.classList.toggle(className, elem.intersectionRatio < 1),
      { threshold: [1], rootMargin: '-1px 0px 0px 0px' }
    );

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
    };
  }, [className]);

  return { ref };
};
