import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useEntriesContext, useScheduleContext } from '../contexts';

export const useShowLoadingSpinner = () => {
  const {
    isLoading: isEntriesLoading,
    isFetching: isEntriesFetching,
    isPlaceholderData: isPreviousEntriesData,
  } = useEntriesContext();

  const { isLoading: isScheduleLoading } = useScheduleContext();

  const isLoadingEntries =
    isScheduleLoading || // schedule itself is loading
    isEntriesLoading || // entries are initially loading
    (isEntriesFetching && isPreviousEntriesData); // fetching a new period of data

  return useDebounceLoading(isLoadingEntries);
};
