import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { isRecurringEntry } from '@/pages/Schedule/utils';
import { useToast } from '@/ui';
import {
  MotionFlex,
  type MotionFlexProps,
  Divider,
  Flex,
  type FlexProps,
  Switch,
  Text,
  forwardRef,
} from '@/ui';
import { EmptyState } from '../../components/EmptyState';
import { PanelBody } from '../../components/PanelBody';
import { PanelContent } from '../../components/PanelContent';
import { PanelHeader } from '../../components/PanelHeader';
import { PanelTitle } from '../../components/PanelTitle';
import { RsvpList } from './components/RsvpList';
import { RsvpSettings } from './components/RsvpSettings';
import { useToggleRsvp } from './hooks/useToggleRsvp';

type Props = FlexProps & {
  onBeforeToggleRsvp: (entry: DecoratedEntry) => Promise<void>;
};

export const RsvpsPanel = ({ onBeforeToggleRsvp, ...props }: Props) => {
  const { t } = useTranslation('entryModal', { keyPrefix: 'rsvps_panel' });
  const { toggleRsvp } = useToggleRsvp();
  const toast = useToast();
  const draftEntry = useDraftEntryStore((store) => store.draftEntry);

  if (!draftEntry) {
    return null;
  }

  const canEnableRSVP = !isRecurringEntry(draftEntry);

  const handleToggle = async () => {
    await onBeforeToggleRsvp(draftEntry);
    toggleRsvp(draftEntry.id, !draftEntry.rsvpSettings.enabled, {
      onError: () => toast.error(t('toggle_error_toast')),
    });
  };

  return (
    <PanelContent {...props}>
      <PanelHeader>
        <PanelTitle>{t('header')}</PanelTitle>
      </PanelHeader>
      <PanelBody>
        <Flex align="center" justify="space-between">
          <Text fontWeight="medium">{t('toggle_label')}</Text>
          <Switch
            isChecked={draftEntry.rsvpSettings.enabled}
            isDisabled={!canEnableRSVP}
            size="lg"
            onChange={handleToggle}
          />
        </Flex>

        <Divider mb="6" mt="4" />

        <AnimatePresence mode="popLayout">
          {draftEntry.rsvpSettings.enabled ? (
            <ContentContainer
              flexDirection="column"
              justifyContent="space-between"
              key="enabled"
            >
              <RsvpList entry={draftEntry} />

              {canEnableRSVP && <RsvpSettings entry={draftEntry} />}
            </ContentContainer>
          ) : !canEnableRSVP ? (
            <ContentContainer
              color="customgray.mid"
              fontSize="lg"
              key="unsupported"
              textAlign="center"
            >
              {t('recurrence_unsupported')}
            </ContentContainer>
          ) : (
            <ContentContainer key="empty">
              <EmptyState>{t('empty_state_text')}</EmptyState>
            </ContentContainer>
          )}
        </AnimatePresence>
      </PanelBody>
    </PanelContent>
  );
};

const ContentContainer = forwardRef((props: MotionFlexProps, ref) => {
  return (
    <MotionFlex
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      flex="1"
      ref={ref}
      transition={{ duration: 0.1 }}
      {...props}
    />
  );
});
