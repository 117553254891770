import { useLastTab } from '../hooks/useLastTab';
import { Redirect } from './Redirect';

export const MagicTabsIndex = () => {
  const { lastTab } = useLastTab();

  const path = lastTab === 'magic' ? '/magic' : '/dashboard';

  return <Redirect path={path} />;
};
