import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const parts = ['container', 'control', 'label'];
const helpers = createMultiStyleConfigHelpers(parts);

export const Radio = helpers.defineMultiStyleConfig({
  baseStyle: ({ colorScheme }) => {
    return {
      control:
        colorScheme === 'whiteAlpha'
          ? {
              color: 'gray.800',
              borderColor: 'gray.200',
              _placeholder: {
                color: 'gray.500',
              },
              _checked: {
                background: 'whiteAlpha.500',
                borderColor: 'whiteAlpha.500',
                color: 'white',
              },
            }
          : {},
    };
  },
});
