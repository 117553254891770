import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shouldRedirectOnLogout } from '@/components/auth';
import { useOpenRegisterModal } from '@/components/RegisterModal';
import { useOpenSettingsModal } from '@/components/SettingsModal';
import { useCurrentUserContext } from '@/contexts';
import { useLogout } from '@/hooks/useLogout';
import {
  useToast,
  Avatar,
  type MenuListProps,
  Flex,
  type FlexProps,
  Icon,
  Menu,
  MenuButton,
  type MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@/ui';

type Props = FlexProps & {
  buttonProps?: MenuButtonProps;
  menuProps?: MenuListProps;
  additionalMenuItems?: JSX.Element;
};

export const ProfileMenu = ({
  buttonProps,
  menuProps,
  additionalMenuItems,
  ...props
}: Props) => {
  const { currentUser, isAuthenticated } = useCurrentUserContext();
  const { t } = useTranslation(['header', 'auth']);
  const { openSettingsModal } = useOpenSettingsModal();
  const { openRegisterModal } = useOpenRegisterModal();
  const toast = useToast();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleAuthClick = () => {
    openRegisterModal({
      onAuthenticated: (result) => {
        toast.notify(
          result == 'register'
            ? t('auth:sign_up_success_toast')
            : t('auth:sign_in_success_toast')
        );
      },
    });
  };

  const handleLogoutClick = () => {
    logout({
      onSuccess: () => {
        const { shouldRedirect, path } = shouldRedirectOnLogout();
        if (shouldRedirect) {
          navigate(path);
        }
      },
    });
  };

  return (
    //  Wrapper exists to silence Popper warning
    //  https://github.com/chakra-ui/chakra-ui/issues/3440
    <Flex align="center" h="100%" {...props}>
      <Menu isLazy placement="bottom-end">
        <MenuButton
          aria-label={t('profile_menu.aria_label')}
          data-group
          h="100%"
          w={{ md: '72px' }}
          {...buttonProps}
        >
          <Avatar
            _groupActive={{ filter: 'brightness(110%)' }}
            _groupHover={{ filter: 'brightness(110%)' }}
            bg="brand.500"
            color="white"
            margin="auto"
            name={currentUser?.name || currentUser?.email || ''}
            size="sm"
            src={currentUser?.avatar ?? undefined}
          >
            {!isAuthenticated && <Icon icon="Menu" />}
          </Avatar>
        </MenuButton>
        <Portal>
          <MenuList data-testid="profile-menu" minW="125px" {...menuProps}>
            {isAuthenticated ? (
              <>
                <MenuItem
                  icon={<Icon icon="Settings" />}
                  onClick={() => openSettingsModal()}
                >
                  {t('profile_menu.settings')}
                </MenuItem>

                {additionalMenuItems}

                <MenuDivider />
                <MenuItem
                  icon={<Icon icon="Logout" />}
                  onClick={handleLogoutClick}
                >
                  {t('auth:sign_out')}
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={handleAuthClick}>
                  {t('auth:sign_in')}
                </MenuItem>
                <MenuItem onClick={handleAuthClick}>
                  {t('auth:create_for_free')}
                </MenuItem>
              </>
            )}
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};
