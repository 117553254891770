import { validate } from 'uuid';

export const getScheduleIdFromSlug = (slug: string): string => {
  const uuid = slug.match(
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  )?.[0];
  if (!uuid) {
    throw new Error('UUID not found in schedule slug');
  }
  if (!validate(uuid)) {
    throw new Error('Schedule slug UUID is invalid');
  }
  return uuid;
};
