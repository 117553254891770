import { useCurrentUserContext } from '@/contexts';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { PrivateHeader } from './components/PrivateHeader/PrivateHeader';
import { PublicHeader } from './components/PublicHeader/PublicHeader';

export const ScheduleHeader = () => {
  const { isPublicRole } = useScheduleContext();
  const { isAuthenticated } = useCurrentUserContext();

  if (!isAuthenticated && isPublicRole) {
    return <PublicHeader />;
  }

  return <PrivateHeader />;
};
