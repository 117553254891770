import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOpenRegisterModal } from '@/components/RegisterModal';
import { useCurrentUserContext } from '@/contexts';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useToast } from '@/ui';
import { Button, type ButtonProps } from '@/ui';
import { useCopyPublicSchedule } from './useCopyPublicSchedule';

export const CopyScheduleButton = (props: ButtonProps) => {
  const { t } = useTranslation('schedule', { keyPrefix: 'copy_schedule' });
  const { trackEvent } = useAnalytics();
  const { scheduleId } = useScheduleContext();
  const { copySchedule, isPending } = useCopyPublicSchedule();
  const { isAuthenticated } = useCurrentUserContext();
  const { openRegisterModal } = useOpenRegisterModal();
  const navigate = useNavigate();
  const toast = useToast();

  const fireMutation = () => {
    trackEvent('schedule:click copy-schedule success', {
      scheduleId,
    });

    copySchedule(scheduleId, {
      onError: () => toast.error(t('error_toast')),
      onSuccess: (data) => {
        navigate('../' + data.copyPublicSchedule);

        toast.notify({
          title: t('success_toast_title'),
          description: t('success_toast_description'),
        });
      },
    });
  };

  const handleClick = () => {
    trackEvent('schedule:click copy-schedule', { scheduleId });

    if (isAuthenticated) {
      fireMutation();
      return;
    }

    openRegisterModal({
      action: 'copy_public_schedule',
      launch: 'copy-public-schedule',
      onAuthenticated: fireMutation,
    });
  };

  useLauncherAction('copy-public-schedule', () => {
    fireMutation();
  });

  return (
    <Button isLoading={isPending} onClick={handleClick} {...props}>
      {t('action')}
    </Button>
  );
};
