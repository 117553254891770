import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';
import { TbGripVertical } from 'react-icons/tb';
import {
  getSnippetsInUse,
  useSnippetIngredientInUseModal,
} from '@/pages/Schedule/components';
import type { LabelType, Label } from '@/types/gql.generated';
import {
  useToast,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useToken,
} from '@/ui';
import { isGQLErrorOfType } from '@/utils/errors';
import { Pill } from '../../Pills';
import { useDeleteLabel } from '../hooks/useDeleteLabel';
import { useUpdateLabel } from '../hooks/useUpdateLabel';
import { ManageMenu } from './ManageMenu';
import { PlainLabelInput } from './PlainLabelInput';

type Props = {
  labelType: LabelType;
  label: Label;
  selected: boolean;
  onToggle: () => void;
  onUpdate: (label: Label) => void;
  onDelete: (label: Label) => void;
  onValidate: (text: string) => boolean;
};

export const LabelSelectItem = ({
  labelType,
  label,
  selected,
  onToggle,
  onUpdate,
  onDelete,
  onValidate,
}: Props) => {
  const { t } = useTranslation('labelSelect');
  const [isEditing, setEditing] = useState(false);
  const toast = useToast();
  const { updateLabel } = useUpdateLabel();
  const { deleteLabel } = useDeleteLabel(labelType);
  const [iconColorLight, iconColorDark] = useToken('colors', [
    'gray.500',
    'gray.800',
  ]);
  const iconColor = useColorModeValue(iconColorLight, iconColorDark);
  const backgroundColor = useColorModeValue('white', 'customgray.darker2');
  const hoverColor = useColorModeValue(
    'customgray.lightest',
    'customgray.alsodarker'
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    activeIndex: activeDragIndex,
    index: listIndex,
    items,
  } = useSortable({ id: label.id });

  const { open: openSnippetIngredientInUseModal } =
    useSnippetIngredientInUseModal();

  const isDragged = activeDragIndex === listIndex;

  return (
    <Flex
      _hover={{ backgroundColor: hoverColor }}
      align="center"
      bgColor={isDragged ? hoverColor : backgroundColor}
      borderRadius="md"
      data-group
      gap="1"
      justify="space-between"
      pl="1"
      pr="2.5"
      py="1"
      ref={setNodeRef}
      transition="background-color 0.2s ease"
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragged ? 1 : 'inherit',
      }}
    >
      <Flex align="center" overflow="hidden">
        <IconButton
          aria-label={t('who.reorder_tag_aria_label')}
          cursor="move"
          disabled={items.length === 1}
          display="flex"
          icon={<Icon as={TbGripVertical} color={iconColor} m="auto" />}
          ref={setActivatorNodeRef}
          size="xs"
          style={{ touchAction: 'none' }}
          variant="unstyled"
          {...attributes}
          {...listeners}
          tabIndex={-1}
        />

        {isEditing ? (
          <PlainLabelInput
            validate
            value={label.text}
            onCancel={() => setEditing(false)}
            onAdd={(text) => {
              if (onValidate(text)) {
                setEditing(false);
                onUpdate({ ...label, text });
                updateLabel(label.id, text, {
                  onError: () => {
                    toast.error(t('toasts.update_error'));
                    onUpdate(label);
                  },
                });
              } else {
                toast.warning(t('toasts.exists_error'));
              }
            }}
          />
        ) : (
          <Pill disabled={!selected} onClick={onToggle}>
            <HStack alignItems="center">
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {label.text}
              </Text>
              {selected && (
                <Box flexShrink={0}>
                  <AiOutlineCheck />
                </Box>
              )}
            </HStack>
          </Pill>
        )}
      </Flex>

      <ManageMenu
        onEdit={() => setEditing(true)}
        onDelete={() => {
          deleteLabel(label.id, {
            onSuccess: () => {
              if (selected) {
                onToggle();
              }
              onDelete(label);
            },
            onError: (err) => {
              if (isGQLErrorOfType(err, 'LabelInUse')) {
                const snippets = getSnippetsInUse(err);
                openSnippetIngredientInUseModal('label', snippets);
              } else {
                toast.error(t('toasts.delete_error'));
              }
            },
          });
        }}
      />
    </Flex>
  );
};
