import { Box } from '@chakra-ui/react';
import type { ScheduleTemplate } from '@/hooks/useScheduleTemplates';
import { getImageUrl } from './bannerLib';

type Props = {
  template: ScheduleTemplate;
};

export const Banner = ({ template }: Props): JSX.Element => {
  return (
    <Box
      bgImage={getImageUrl(template)}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      pt="20%"
      w="100%"
    />
  );
};
