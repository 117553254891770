import pick from 'lodash.pick';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useScheduleContext } from '../contexts';

type State = {
  isShowingHiddenInstances: boolean;
  toggleHiddenInstances: () => void;
};

const useStore = create<State>()(
  persist(
    (set, get) => ({
      isShowingHiddenInstances: false,
      toggleHiddenInstances: () => {
        const value = get().isShowingHiddenInstances;
        const nextValue = !value;
        set({ isShowingHiddenInstances: nextValue });
      },
    }),
    {
      name: '_ah_show_hidden_entries',
      partialize: (state) => pick(state, 'isShowingHiddenInstances'),
    }
  )
);

export const useToggleHiddenInstances = () => {
  const { isPublicRole } = useScheduleContext();
  const store = useStore();
  const { value: hideItemsEnabled } = useFeatureFlag('hideItemsEnabled');

  const isShowingHiddenInstances =
    !hideItemsEnabled || isPublicRole ? false : store.isShowingHiddenInstances;

  return {
    ...store,
    isShowingHiddenInstances,
  };
};
