import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type Tab = 'magic' | 'dashboard';

type State = {
  lastTab: Tab | null;
  setLastTab: (tab: Tab | null) => void;
};

export const useLastTab = createWithEqualityFn<State>()(
  persist(
    (set) => ({
      lastTab: null,
      setLastTab: (lastTab) => set({ lastTab }),
    }),
    {
      name: '_ah_last_magic_tab',
    }
  ),
  shallow
);
