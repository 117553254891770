import { v4 as uuid } from 'uuid';
import { COLORS } from './colors';
import type { Category, CompleteCategory } from './types';

export const DEFAULT_COLOR = COLORS[0].color;

export const factory = (
  properties: Partial<Category> = {}
): CompleteCategory => {
  return {
    id: properties.id || uuid(),
    text: properties.text || null,
    color: properties.color || DEFAULT_COLOR,
  };
};
