/** Maximum height of the modal in pixels */
export const MODAL_MAX_HEIGHT = 675;

/** Width of the modal in pixels without the sidebar open */
export const MODAL_WIDTH = 675;

/** The amount of vertical spacing (distributed evenly amongst top and bottom) on non-mobile screens in pixels */
export const MODAL_VERTICAL_GUTTER = 40;

/** Width of the sidebar in pixels on non-mobile screens */
export const MODAL_SIDEBAR_WIDTH = 400;

/** Height of the header in pixels */
export const HEADER_HEIGHT = 65;

/** Default 'minutes before' setting for relative messages */
export const DEFAULT_RELATIVE_MINUTES = 15;
