import { gql } from 'graphql-request';

export const SnippetFragment = gql`
  fragment Snippet on Snippet {
    id
    name
    scheduleId
    scheduleTimeZone
    visibility
    categories {
      id
      text
      color
    }
    whoLabels {
      id
      text
    }
    labels {
      id
      text
    }
    feeds {
      id
      title
    }
  }
`;
