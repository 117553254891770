import { useTranslation } from 'react-i18next';
import { useToast } from '@/ui';
import { factory } from '../categoryLib';
import { useCategorySelectContext } from '../hooks/useCategorySelectContext';
import { useUpdateCategory } from '../hooks/useUpdateCategory';
import type { Category } from '../types';
import { CategoryForm } from './CategoryForm';

type Props = {
  onUpdate?: (category: Category | null) => void;
};

export const CategoryEdit = ({ onUpdate }: Props): JSX.Element => {
  const { t } = useTranslation('categorySelect');
  const { goBack, category } = useCategorySelectContext();
  const { updateCategory } = useUpdateCategory();
  const toast = useToast();

  const handleUpdate = (category: Category, onSuccess?: () => void) => {
    onUpdate?.(category);

    updateCategory(category, {
      onSuccess,
      onError: (err, variables, context) => {
        toast.error(t('update_error'));

        if (context?.prevCategory) {
          onUpdate?.(context.prevCategory);
        }
      },
    });
  };

  return (
    <CategoryForm
      category={category || factory()}
      onCancel={(category) => handleUpdate(category, goBack)}
      onChange={handleUpdate}
      onSubmit={(category) => handleUpdate(category, goBack)}
    />
  );
};
