import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

type UseOptimisticValue = <T>(value: T) => [T, (value: T) => void];

export const useOptimisticValue: UseOptimisticValue = <T>(value: T) => {
  const [optimisticValue, setOptimisticValue] = useState<T>(value);

  useUpdateEffect(() => {
    setOptimisticValue(value);
  }, [value]);

  return [optimisticValue, setOptimisticValue];
};
