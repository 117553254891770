export const FormLabel = {
  baseStyle: {
    fontWeight: '400',
    _dark: {
      color: 'white',
    },
  },
  variants: {
    uppercase: {
      color: 'customgray.dark',
      fontWeight: '500',
      fontSize: 'xs',
      lineHeight: '14.2px',
      letterSpacing: '.15em',
      textTransform: 'uppercase',
    },
  },
};
