import { RRule } from 'rrule';

// These are deliberately in order based on their `weekday` property
// 0 = Monday, 6 = Sunday
export const WeekDayNumbers = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
] as const;
