import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  path: string;
};

export const Redirect = ({ path }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({ ...location, pathname: path }, { replace: true });
    // eslint-disable-next-line
  }, []);

  return null;
};
