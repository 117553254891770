import { useEffect, useState } from 'react';
import { breakpoints } from '@/theme';

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches
  );

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export const useIsBreakpoint = (breakpoint: keyof typeof breakpoints) => {
  // Intentially not using `useBreakpointValue` or `useMediaQuery` from
  // Chakra here -- it's not performant and triggers too many re-renders.
  return useMediaQuery(`(max-width: ${breakpoints[breakpoint]})`);
};

export const useIsMobileBreakpoint = () => {
  return useIsBreakpoint('md');
};

export const useIsShortScreen = () => {
  return useMediaQuery('(max-height: 800px)');
};

export const useIsTallScreen = () => {
  return useMediaQuery('(min-height: 1200px)');
};

/** Returns `true` is the device is most likely a mobile device */
export const useIsMobile = () => {
  const isMobile = useMediaQuery('(pointer: coarse) and (hover: none)');
  return { isMobile };
};
