import { useMemo, type ReactNode } from 'react';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { createContext } from '@/utils';
import { getConfig } from '../config';
import type { PageExperienceConfig, PageExperienceSlug } from '../config/types';
import { filterPastEntries } from './utils';

type State = {
  config: PageExperienceConfig;
  entries: DecoratedQuickCreateEntry[] | undefined;
};

type Props = {
  slug: PageExperienceSlug;
  children: ReactNode | ((value: State) => ReactNode);
};

export const PageExperienceContext = createContext<State | undefined>(
  undefined,
  'QuickCreateContext'
);

export const PageExperienceProvider = ({ slug, children }: Props) => {
  const { job } = useQuickCreateContext();

  const config = useMemo(() => getConfig(slug), [slug]);

  const entries = useMemo<DecoratedQuickCreateEntry[] | undefined>(() => {
    if (!job?.entries) {
      return undefined;
    }
    return config.entries?.hidePastEntries
      ? filterPastEntries(job.entries)
      : job.entries;
  }, [job?.entries, config.entries?.hidePastEntries]);

  const value: State = {
    config,
    entries,
  };

  return (
    <PageExperienceContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </PageExperienceContext.Provider>
  );
};
