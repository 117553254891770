export const Modal = {
  baseStyle: {
    dialogContainer: {
      // https://github.com/chakra-ui/chakra-ui/issues/4680
      '@supports(height: -webkit-fill-available)': {},
    },
  },
  variants: {
    primary: {
      dialog: {
        margin: 0,
        h: {
          base: '100%',
          md: 'auto',
        },
        minW: {
          base: '100vw',
          md: '40rem',
        },
        minH: {
          base: 'var(--app-height)',
          md: 'auto',
        },
        borderRadius: {
          base: 0,
          md: 20,
        },
      },
      header: {
        px: {
          base: 6,
          md: 8,
        },
        pt: 7,
        pb: 6,
        display: 'flex',
        flexDir: 'row',
        gap: 3,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: 'customgray.gray',
        fontWeight: '500',
        lineHeight: '1',
      },
      body: {
        p: {
          base: 6,
          md: 8,
        },
        overflowY: 'auto',
      },
      closeButton: {
        top: '22px',
        right: 6,
      },
      footer: {
        px: {
          base: 6,
          md: 8,
        },
        py: 4,
        borderTopWidth: 1,
        borderTopColor: 'customgray.gray',
      },
    },
  },
};
