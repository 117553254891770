import { ScheduleType } from '@/types/gql.generated';
import { type ScheduleView, ScheduleViews } from '../types';
import type { DecoratedViewModule, ViewModule } from './types';

type Params = {
  viewModules: DecoratedViewModule[];
  isMobileBreakpoint: boolean;
  queryParams: URLSearchParams;
  scheduleType: ScheduleType;
  lastSelectedView?: ScheduleView;
};

const DEFAULT_VIEWS_BY_TYPE: Record<ScheduleType, ScheduleView> = {
  CLASSIC: 'week',
  LIST: 'week',
  CONFLICT: 'conflict',
  MAGIC: 'week',
};

const isScheduleView = (view: unknown): view is ScheduleView => {
  return (
    typeof view === 'string' && ScheduleViews.includes(view as ScheduleView)
  );
};

export const getActiveViewModule = ({
  viewModules,
  queryParams,
  scheduleType,
  isMobileBreakpoint,
  lastSelectedView,
}: Params): ViewModule => {
  const defaultView = lastSelectedView || DEFAULT_VIEWS_BY_TYPE[scheduleType];
  const requestedView = queryParams.get('view');
  const attemptedView = isScheduleView(requestedView)
    ? requestedView
    : defaultView;

  let attemptedViewModule = viewModules.find(
    (viewModule) => viewModule.id === attemptedView && viewModule.isEnabled
  );

  if (!attemptedViewModule) {
    attemptedViewModule = viewModules.find(
      (viewModule) => viewModule.id === defaultView
    );
  }

  if (!attemptedViewModule) {
    // We should never hit this
    throw new Error('Unable to determine view');
  }

  if (isMobileBreakpoint && attemptedViewModule.mobileViewModule) {
    return attemptedViewModule.mobileViewModule;
  }

  return attemptedViewModule;
};

type WithOptionalProps<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const createViewModule = (
  config: WithOptionalProps<
    ViewModule,
    | 'heroEnabled'
    | 'bannerEnabled'
    | 'actionBarEnabled'
    | 'fetchMessagesWithEntries'
    | 'scheduleTypes'
  >
): ViewModule => ({
  bannerEnabled: true,
  heroEnabled: true,
  actionBarEnabled: true,
  fetchMessagesWithEntries: false,
  scheduleTypes: [ScheduleType.Classic, ScheduleType.List],
  ...config,
});
