import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ScheduleFragment } from '@/fragments/schedule';
import { useAnalytics } from '@/hooks/useAnalytics';
import { gqlClient } from '@/lib';
import { getLocalTimeZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import type {
  CreateScheduleFromTemplateMutation,
  CreateScheduleFromTemplateMutationVariables,
} from './useCreateScheduleFromTemplate.generated';

const query = gql`
  ${ScheduleFragment}
  mutation CreateScheduleFromTemplate(
    $templateId: String!
    $timeZone: TimeZone
  ) {
    createScheduleFromTemplate(templateId: $templateId, timeZone: $timeZone) {
      ...Schedule
    }
  }
`;

export const useCreateScheduleFromTemplate = () => {
  const { incrementUserProperty } = useAnalytics();

  const { mutate, ...rest } = useMutation<
    CreateScheduleFromTemplateMutation['createScheduleFromTemplate'],
    QueryError,
    CreateScheduleFromTemplateMutationVariables
  >({
    mutationFn: async (variables) => {
      const result = await gqlClient.request<
        CreateScheduleFromTemplateMutation,
        CreateScheduleFromTemplateMutationVariables
      >(query, variables);
      return result?.createScheduleFromTemplate;
    },
  });

  const createScheduleFromTemplate = (
    variables: Omit<CreateScheduleFromTemplateMutationVariables, 'timeZone'>,
    options: MutateOptions<
      CreateScheduleFromTemplateMutation['createScheduleFromTemplate'],
      QueryError,
      CreateScheduleFromTemplateMutationVariables
    > = {}
  ) => {
    const { onSuccess, ...restOptions } = options;
    mutate(
      {
        timeZone: getLocalTimeZone(),
        ...variables,
      },
      {
        ...restOptions,
        onSuccess: (...args) => {
          incrementUserProperty('scheduleCount', 1);
          onSuccess?.(...args);
        },
      }
    );
  };

  return {
    ...rest,
    createScheduleFromTemplate,
  };
};
