import { useState } from 'react';

type OpenArg = {
  href: string;
};

export const useAuthPopup = () => {
  const [isBlocked, setIsBlocked] = useState(false);

  const open = ({ href }: OpenArg) => {
    setIsBlocked(false);
    const tab = window.open(href, '_blank');

    try {
      tab?.focus();
    } catch (err) {
      setIsBlocked(true);
    }
  };

  return {
    open,
    isBlocked,
  };
};
