import {
  type MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import {
  type EntriesCacheItem,
  useEntriesCache,
  useScheduleContext,
} from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import {
  createEntriesQueryKey,
  createEntryQueryKey,
  createAddContentJobResultsQueryKey,
} from '@/utils/queryKeys';
import type {
  DeleteEntryMutation,
  DeleteEntryMutationVariables,
} from './useDeleteEntry.generated';

const query = gql`
  mutation DeleteEntry($scheduleId: ID!, $entryId: ID!) {
    deleteEntry(scheduleId: $scheduleId, entryId: $entryId)
  }
`;

type Context = {
  prevData: EntriesCacheItem[];
};

export const useDeleteEntry = () => {
  const { scheduleId } = useScheduleContext();
  const queryClient = useQueryClient();
  const entriesCache = useEntriesCache();

  const { mutate, isPending } = useMutation<
    DeleteEntryMutation,
    QueryError,
    DeleteEntryMutationVariables,
    Context
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        DeleteEntryMutation,
        DeleteEntryMutationVariables
      >(query, variables);
    },
    onMutate: ({ entryId }) => {
      // take a snapshot of all the caches where this entry exists
      const prevData = entriesCache.getCachesHoldingEntry(entryId);
      entriesCache.remove(entryId);
      return { prevData };
    },
    onError: (err, variables, context) => {
      // rollback cache to the snapshots
      context?.prevData.forEach(({ queryKey, data }) => {
        queryClient.setQueryData(queryKey, data);
      });
    },
    onSettled: (data, err, variables) => {
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });
      queryClient.invalidateQueries({
        queryKey: createEntryQueryKey(scheduleId, variables.entryId),
      });
      queryClient.invalidateQueries({
        queryKey: createAddContentJobResultsQueryKey(scheduleId),
      });
    },
  });

  const deleteEntry = useCallback(
    (
      entryId: string,
      options?: MutateOptions<
        DeleteEntryMutation,
        QueryError,
        DeleteEntryMutationVariables
      >
    ) => mutate({ scheduleId, entryId }, options),
    [mutate, scheduleId]
  );

  return {
    deleteEntry,
    isPending,
  };
};
