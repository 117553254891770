import { gql } from 'graphql-request';

export const ScheduleFragment = gql`
  fragment Schedule on Schedule {
    id
    type
    title
    slug
    description
    role
    isSaved
    isPublic
    isCopyable
    activeUsers
    timeZone
    socialSharingEnabled
    cta {
      text
      link
    }
    categories {
      id
      text
      color
    }
    labels {
      id
      text
      sortOrder
    }
    whoLabels {
      id
      text
      sortOrder
    }
    feeds {
      id
      title
    }
    header {
      id
      enabled
      imageBaseUrl
      type
    }
    limits {
      memberCount
      entryScheduledMessageCount
    }
    memberForm {
      id
      title
      description
      fields {
        id
        order
        label
        type
        helpText
      }
    }
    selfMemberFormSubmission {
      label
      type
      value
    }
    rsvpSettings {
      question
      yesLabel
      noLabel
    }
  }
`;
