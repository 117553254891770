import { useState } from 'react';
import { useQueryParams } from '@/hooks/useQueryParams';
import { QuickEntriesJobType } from '@/types/gql.generated';

const isQuickEntriesJobType = (
  value: unknown
): value is QuickEntriesJobType => {
  return (
    typeof value === 'string' &&
    Object.values(QuickEntriesJobType).includes(value as QuickEntriesJobType)
  );
};

export const useInitialPromptType = () => {
  const { queryParams } = useQueryParams();

  return useState<QuickEntriesJobType>(() => {
    const initialPromptType = (
      queryParams.get('initialPromptType') ?? ''
    ).toUpperCase();

    return isQuickEntriesJobType(initialPromptType)
      ? initialPromptType
      : QuickEntriesJobType.Prompt;
  });
};
