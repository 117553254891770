import { AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button, MotionFlex } from '@/ui';
import { useCreateScheduleFromTemplateLauncher } from './hooks/useCreateScheduleFromTemplateLauncher';
import { useFallbackMode } from './hooks/useFallbackMode';

const variants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

export const Launch = () => {
  const { hasFallback } = useFallbackMode();

  useCreateScheduleFromTemplateLauncher();

  return (
    <AnimatePresence>
      {hasFallback && (
        <MotionFlex
          align="center"
          animate="enter"
          direction="column"
          exit="exit"
          h="100vh"
          initial="exit"
          justify="center"
          variants={variants}
        >
          <Button as={Link} colorScheme="dark" to="/">
            Go to Dashboard
          </Button>
        </MotionFlex>
      )}
    </AnimatePresence>
  );
};
