import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import * as dateUtils from '@/utils/dates';

/** Date utilities scoped to the current schedule's time zone */
export const useTimeZonedDateUtils = () => {
  const { timeZone } = useScheduleContext();

  /** Is a `date` today in the schedule's time zone? */
  const isToday = (date: DateTime): boolean => {
    return dateUtils.isToday(date, timeZone);
  };

  /** Get the current time stamp in the schedule's time zone */
  const local = useCallback((): DateTime => {
    return DateTime.local({ zone: timeZone });
  }, [timeZone]);

  /** Create a DateTime instance in schedule's time zone */
  const fromFormat: typeof DateTime.fromFormat = useCallback(
    (date, format, opts) => {
      return DateTime.fromFormat(date, format, {
        ...opts,
        zone: timeZone,
      });
    },
    [timeZone]
  );

  /** Create a DateTime instance in schedule's time zone */
  const fromJSDate: typeof DateTime.fromJSDate = useCallback(
    (date, opts) => {
      return DateTime.fromJSDate(date, {
        ...opts,
        zone: timeZone,
      });
    },
    [timeZone]
  );

  return {
    isToday,
    local,
    fromFormat,
    fromJSDate,
  };
};
