type ClientName = 'platform' | 'magic' | 'extension';

export const getClientName = (): ClientName => {
  const { pathname } = window.location;

  if (pathname.startsWith('/magic-extension')) {
    return 'extension';
  }
  if (pathname.startsWith('/magic')) {
    return 'magic';
  }
  return 'platform';
};
