import pick from 'lodash.pick';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type State = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const useRequestAccessModalState = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
  }),
  shallow
);

export const useRequestAccessModal = () => {
  return useRequestAccessModalState((state) =>
    pick(state, ['isOpen', 'open', 'close'])
  );
};
