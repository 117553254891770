import { ScheduleType } from '@/types/gql.generated';
import { createViewModule } from '../utils';
import {
  adjustEndDate,
  adjustStartDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
} from './handlers';

export const ConflictModule = createViewModule({
  id: 'conflict',
  scheduleTypes: [ScheduleType.Conflict],
  heroEnabled: false,
  bannerEnabled: false,
  actionBarEnabled: false,
  adjustStartDate,
  adjustEndDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
  containerProps: {
    bg: { base: 'white', md: 'transparent' },
  },
});
