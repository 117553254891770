import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '@/contexts';
import { IdentityStrategy } from '@/types/gql.generated';
import { PanelContent } from '../..//components/PanelContent';
import { PanelHeader } from '../../components/PanelHeader';
import { SectionHeader } from '../../components/SectionHeader';
import { PasswordForm } from './components/PasswordForm';
import { ProfileForm } from './components/ProfileForm';

export const Account = () => {
  const { currentUser } = useCurrentUserContext();
  const { t } = useTranslation('settingsModal', { keyPrefix: 'account' });
  const hasPasswordAuth = currentUser?.identityStrategies.some(
    (identity) => identity === IdentityStrategy.Password
  );

  return (
    <>
      <PanelHeader>{t('heading')}</PanelHeader>
      <PanelContent>
        <ProfileForm />

        {hasPasswordAuth && (
          <>
            <SectionHeader>{t('password_section.heading')}</SectionHeader>
            <PasswordForm />
          </>
        )}
      </PanelContent>
    </>
  );
};
