import { gql } from 'graphql-request';

/**
 * These are the minimum fields required to generate instances
 * from entries and present them in a useful way.
 */
export const MinimumEntryFieldsFragment = gql`
  fragment MinimumEntryFields on Entry {
    id
    contextId
    exclusions
    hidden
    timeZone
    createdAt
    recurrences {
      startDate
      endDate
      isOnDay
      rule
    }
  }
`;

export const EntryFragment = gql`
  ${MinimumEntryFieldsFragment}
  fragment Entry on Entry {
    ...MinimumEntryFields
    title
    emoji
    feed {
      id
      title
      primarySchedule {
        id
      }
    }
    type
    description
    notes
    isFollowing
    rsvpSettings {
      enabled
    }
    locationWithPlace {
      name
      googlePlaceId
    }
    category {
      id
      color
      text
    }
    whoLabels {
      id
      text
      sortOrder
    }
    labels {
      id
      text
      sortOrder
    }
  }
`;
