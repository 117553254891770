import { useEffect, useState } from 'react';

type CloneEntryState = 'default' | 'cloning' | 'done';

export const useCloneStatus = () => {
  const [status, setStatus] = useState<CloneEntryState>('default');

  const startCloneEntryStateTransition = () => {
    setStatus('cloning');
  };

  // transition from cloning -> done
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (status === 'cloning') {
      timeoutId = setTimeout(() => setStatus('done'), 800);
    }
    return () => clearTimeout(timeoutId);
  }, [status]);

  // transition from done -> default
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (status === 'done') {
      timeoutId = setTimeout(() => setStatus('default'), 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [status]);

  return {
    cloneStatus: status,
    startCloneEntryStateTransition,
  };
};
