import slugify from 'slugify';
import { i18n } from '@/i18n';

type ScheduleLike = {
  id: string;
  title?: string | null;
};

export function getScheduleSlug({ id, title }: ScheduleLike): string {
  // Fastify's default URL param length is 100 characters
  // Subtract another 1 for the hyphen between the slug and the ID
  const maxSlugLength = 100 - id.length - 1;

  let slug = slugify(title ?? i18n.t('common:untitled_schedule'), {
    lower: true,
    strict: true,
  });

  if (slug.length > maxSlugLength) {
    slug = slug.slice(0, maxSlugLength);
  }

  return `${slug}${slug ? '-' : ''}${id}`;
}
