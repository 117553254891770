export const Container = {
  variants: {
    max: {
      maxW: 'container.xl',
      px: {
        base: 4,
        sm: 8,
      },
    },
  },
};
