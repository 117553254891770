export const Text = {
  variants: {
    light: {
      opacity: 0.4,
    },
    mid: {
      opacity: 0.6,
    },
  },
};
