import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { useOpenRegisterModal } from '@/components/RegisterModal';
import { useCurrentUserContext } from '@/contexts';
import { useLauncherAction } from '@/hooks/useLauncher';
import {
  ErrorState,
  ScheduleContent,
  ScheduleLayout,
} from '@/pages/Schedule/components';
import {
  ScheduleContextProvider,
  ScheduleFiltersContextProvider,
  useScheduleContext,
} from '@/pages/Schedule/contexts';
import {
  useScheduleOpenedSubscription,
  useScheduleUpdatedSubscription,
} from '@/pages/Schedule/hooks';
import { isGQLErrorOfType } from '@/utils/errors';
import { SuspenseContainer } from '../SuspenseContainer';
import { InvalidPermissionsError } from './InvalidPermissionsError';
import { useSyncScheduleSlug } from './useSyncScheduleSlug';

const ScheduleRenderer = () => {
  const { t } = useTranslation('schedule');
  const location = useLocation();
  const { isAuthenticated } = useCurrentUserContext();
  const {
    error,
    isLoading: isLoadingSchedule,
    isPublicRole,
  } = useScheduleContext();
  const { openRegisterModal } = useOpenRegisterModal();

  useLauncherAction('signup', (params) => {
    if (!isAuthenticated) {
      openRegisterModal({ defaults: params });
    }
  });
  useSyncScheduleSlug();
  useScheduleUpdatedSubscription();
  useScheduleOpenedSubscription();

  if (isLoadingSchedule) {
    return <AppLoader data-testid="schedule-loader" />;
  }

  // Handle schedule not found
  if (isGQLErrorOfType(error, 'ScheduleNotFound')) {
    return (
      <ScheduleLayout>
        <ErrorState title={t('errors.not_found.title')} />
      </ScheduleLayout>
    );
  }

  // Handle invalid permissions
  if (isGQLErrorOfType(error, 'InvalidPermission')) {
    return (
      <ScheduleLayout>
        <InvalidPermissionsError />
      </ScheduleLayout>
    );
  }

  // Handle other unknown schedule errors
  if (error) {
    return (
      <ScheduleLayout>
        <ErrorState title={t('errors.unknown.title')} />
      </ScheduleLayout>
    );
  }

  // Public user attempts to view a private schedule
  if (!isAuthenticated && !isPublicRole) {
    return <Navigate replace state={{ from: location }} to="/auth" />;
  }

  return (
    <ScheduleLayout>
      <SuspenseContainer
        fallback={
          <ScheduleContent>
            <AppLoader data-testid="bundle-loader" />
          </ScheduleContent>
        }
      />
    </ScheduleLayout>
  );
};

export const ScheduleLoader = () => {
  const { slug } = useParams<{ slug: string }>();
  const { isLoading: isLoadingUser } = useCurrentUserContext();

  if (!slug) {
    return <Navigate to="/" />;
  }

  if (isLoadingUser) {
    return <AppLoader data-testid="profile-loader" />;
  }

  return (
    <ScheduleContextProvider scheduleSlug={slug}>
      <ScheduleFiltersContextProvider>
        <ScheduleRenderer />
      </ScheduleFiltersContextProvider>
    </ScheduleContextProvider>
  );
};
