import { useEffect, useState } from 'react';

const FALLBACK_TIMEOUT = 3000;

export const useFallbackMode = () => {
  const [hasFallback, setHasFallback] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasFallback(true);
    }, FALLBACK_TIMEOUT);

    return () => clearTimeout(timeout);
  });

  return { hasFallback };
};
