import type { ScheduleTemplate } from '@/hooks/useScheduleTemplates';
import { Card } from '@/pages/Dashboard/components/Card';
import { Button, Text } from '@/ui';
import { Banner } from './Banner';

type Props = {
  template: ScheduleTemplate;
  onClick: (template: ScheduleTemplate) => void;
};

export const TemplateCard = ({ template, onClick }: Props): JSX.Element => {
  return (
    <Card
      _active={{ transform: 'scale(0.98)' }}
      _hover={{ shadow: '0px 0px 10px 0px var(--chakra-colors-purple-300)' }}
      align="stretch"
      as={Button}
      h="auto"
      justifyContent="flex-start"
      p="0"
      variant="unstyled"
      onClick={() => onClick(template)}
    >
      <Banner template={template} />
      <Text
        fontSize={{ base: '1rem', md: '0.9rem' }}
        fontWeight="medium"
        p={{ base: '4', md: '3' }}
      >
        {template.name}
      </Text>
    </Card>
  );
};
