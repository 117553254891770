import { useMatch } from 'react-router-dom';

type ReturnValue = {
  isResultsSharePage: boolean;
  isPlatformSharePage: boolean;
  isSharePage: boolean;
};

export const useIsSharePage = (): ReturnValue => {
  const isResultsSharePage = Boolean(useMatch('/magic/s/*'));
  const isPlatformSharePage = Boolean(useMatch('/magic/p/*'));
  const isSharePage = isResultsSharePage || isPlatformSharePage;

  return {
    isResultsSharePage,
    isPlatformSharePage,
    isSharePage,
  };
};
