import { BsPin, BsPinFill } from 'react-icons/bs';
import { useCurrentUserContext } from '@/contexts';
import { useSetPreferredPlatform } from '@/hooks/useSetPreferredPlatform';
import { useAuthModal } from '@/pages/QuickCreate/components/AuthModal';
import { useIsHoverSupported } from '@/pages/QuickCreate/hooks/useIsHoverSupported';
import { usePinChangeTip } from '@/pages/QuickCreate/hooks/usePinChangeTip';
import { usePlatformPinTooltip } from '@/pages/QuickCreate/hooks/usePlatformPinTip';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import type { PlatformTypes } from '@/types/gql.generated';
import { Icon, IconButton, forwardRef } from '@/ui';
import { ensurePlatform, getName } from '@/utils/platformLib';

type Props = {
  platform: PlatformTypes;
  entry?: DecoratedQuickCreateEntry;
  hasPlatformTip?: boolean;
  isAlwaysVisible?: boolean;
};

export const PlatformPin = forwardRef(
  (
    { platform, entry, hasPlatformTip, isAlwaysVisible = false }: Props,
    ref
  ) => {
    const isHoverSupported = useIsHoverSupported();
    const { isAuthenticated, currentUser } = useCurrentUserContext();
    const { data: integrations = [] } = useIntegrations();
    const { open: openAuthModal } = useAuthModal();
    const { setPreferredPlatform } = useSetPreferredPlatform();
    const { trigger: triggerPinChangeTip } = usePinChangeTip();
    const { dismiss, shouldOpen: shouldTipOpen } = usePlatformPinTooltip();
    const hasPinned = currentUser?.preferredPlatform
      ? ensurePlatform(currentUser.preferredPlatform, integrations) === platform
      : false;

    const handlePin = () => {
      if (!isAuthenticated) {
        return openAuthModal({
          reason: 'set-preferred-platform',
          platform,
        });
      }

      setPreferredPlatform(hasPinned ? null : platform, {
        onSuccess: () => {
          dismiss();
          if (!hasPinned && entry) {
            triggerPinChangeTip(entry, platform);
          }
        },
      });
    };

    return (
      <IconButton
        _groupHover={{ opacity: 1 }}
        _hover={{ bg: 'gray.200' }}
        pos="absolute"
        ref={ref}
        right="1"
        size="xs"
        transitionDuration="var(--chakra-transition-duration-ultra-fast)"
        transitionProperty="opacity"
        transitionTimingFunction="var(--chakra-transition-easing-ease-in)"
        variant="unstyled"
        aria-label={
          hasPinned ? `Unpin ${getName(platform)}` : `Pin ${getName(platform)}`
        }
        icon={
          <Icon
            as={hasPinned ? BsPinFill : BsPin}
            color={hasPinned ? 'purple.600' : 'gray.700'}
            pos="relative"
            top="1"
          />
        }
        opacity={
          isAlwaysVisible || hasPinned || (hasPlatformTip && shouldTipOpen)
            ? 1
            : isHoverSupported
              ? 0
              : 0.5
        }
        onClick={handlePin}
      />
    );
  }
);
