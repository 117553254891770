// Note: toasts and alerts are the same thing

export const Alert = {
  baseStyle: {
    container: {
      borderRadius: 4,
    },
    description: {
      a: {
        textDecoration: 'underline',
      },
    },
  },
  variants: {
    notify: {
      container: {
        bg: '#343434',
        color: 'white',
        button: {
          color: 'white',
          textDecoration: 'underline',
          _hover: {
            color: 'gray.300',
          },
        },
      },
    },
    purple: {
      container: {
        bg: '#716ADD',
        borderRadius: 'xl',
        color: 'white',
      },
    },
  },
};
