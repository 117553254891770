import { gql } from 'graphql-request';
import { QuickEntryFragment } from './QuickEntryFragment';

export const QuickEntriesJobFragment = gql`
  ${QuickEntryFragment}
  fragment QuickEntriesJob on QuickEntriesJob {
    id
    type
    status
    source
    sourcePrompt
    errorCode
    errorMessage
    createdAt
    prompt
    scheduleId
    file {
      id
      filename
      mimetype
      url
    }
    quickEntries {
      ...QuickEntry
    }
  }
`;
