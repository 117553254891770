import type { CreateScheduleMutationVariables } from '@/hooks/useCreateSchedule/useCreateSchedule.generated';
import {
  Box,
  Flex,
  forwardRef,
  MotionFlex,
  type MotionFlexProps,
  Text,
} from '@/ui';
import type { ScheduleOption } from '../../types';
import { BlankCanvasText } from './BlankCanvasText';
import { FamilyCalendarText } from './FamilyCalendarText';
import { OptionButton } from './OptionButton';
import templatesBg from './templatesBg.png';

type Props = Omit<MotionFlexProps, 'onClick'> & {
  onCreate: (
    option: ScheduleOption,
    variables?: Partial<CreateScheduleMutationVariables>
  ) => void;
  onOpenTemplates: () => void;
};

export const IndexState = forwardRef<Props, typeof MotionFlex>(
  function IndexStateWithRef({ onCreate, onOpenTemplates, ...props }, ref) {
    const handleBlankClick = () => {
      onCreate('blank');
    };

    const handleFamilyClick = () => {
      onCreate('family');
    };

    const handleTemplateClick = () => {
      onOpenTemplates();
    };

    return (
      <MotionFlex direction="column" flex="1" ref={ref} {...props}>
        <Box
          fontSize={{ base: '36px', md: '48px' }}
          fontWeight="bold"
          lineHeight={{ base: '42px', md: '56px' }}
          mb={{ base: '8', md: '16' }}
          mt="6"
          textAlign="center"
        >
          <Text fontSize="56px" mb="4">
            ✨
          </Text>
          <Text>Let&apos;s make a</Text>
          <Text textStyle="magic">dazzling schedule</Text>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          flex={{ md: 1 }}
          gap="3"
        >
          <OptionButton onClick={handleBlankClick}>
            <Text>Start with a</Text>
            <BlankCanvasText />
          </OptionButton>

          <OptionButton onClick={handleFamilyClick}>
            <Text fontSize="48px">🏡</Text>
            <Text>Create a</Text>
            <FamilyCalendarText />
          </OptionButton>

          <OptionButton
            bgImage={templatesBg}
            bgPos="center"
            bgRepeat="no-repeat"
            bgSize="contain"
            onClick={handleTemplateClick}
          >
            <Text>Create from</Text>
            <Text>a template</Text>
          </OptionButton>
        </Flex>
      </MotionFlex>
    );
  }
);
