import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { gqlClient } from '@/lib';
import { Role } from '@/types/gql.generated';
import { createSchedulesQueryKey } from '@/utils/queryKeys';
import type {
  GetSchedulesQuery,
  GetSchedulesQueryVariables,
} from './useSchedules.generated';

const query = gql`
  query GetSchedules {
    getSchedules {
      id
      title
      role
      isSaved
      openedAt
      timeZone
      permissions {
        all {
          id
          name
          email
          avatar
        }
      }
      header {
        id
        enabled
        imageBaseUrl
      }
    }
  }
`;

export type Schedule = GetSchedulesQuery['getSchedules'][number];

type GroupedSchedules = {
  allSchedules: Schedule[];
  mySchedules: Schedule[];
  sharedSchedules: Schedule[];
};

const selector = (data: GetSchedulesQuery) => {
  return data.getSchedules.reduce<GroupedSchedules>(
    (acc, schedule) => {
      acc.allSchedules.push(schedule);

      if (schedule.role === Role.Owner) {
        acc.mySchedules.push(schedule);
      } else {
        acc.sharedSchedules.push(schedule);
      }

      return acc;
    },
    { allSchedules: [], mySchedules: [], sharedSchedules: [] }
  );
};

export const useSchedules = () => {
  const { isAuthenticated } = useCurrentUserContext();

  return useQuery({
    queryKey: createSchedulesQueryKey(),
    queryFn: () => {
      return gqlClient.request<GetSchedulesQuery, GetSchedulesQueryVariables>(
        query
      );
    },
    select: selector,
    enabled: isAuthenticated,
    placeholderData: keepPreviousData,
  });
};
