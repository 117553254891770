import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { config } from '@/config';
import { getClientName } from '@/utils/getClientName';

Sentry.init({
  enabled: config.sentry.enabled,
  environment: config.env,
  dsn: config.sentry.dsn,
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 0.2,
  normalizeDepth: 10,
  maxValueLength: 10_000,
  allowUrls: ['https://agendahero.com', 'https://server.dev'],
  release: config.release,
  ignoreErrors: [
    'Importing a module script failed',
    'Failed to fetch dynamically imported module',
    "Can't find variable: BroadcastChannel",
    'Websocket closed: 4004, Bad response',
    'Promise.withResolvers is not a function',
  ],
  initialScope: (scope) => {
    scope.setTags({
      version: config.version,
      client: getClientName(),
    });
    return scope;
  },
});

export { Sentry };
