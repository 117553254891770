import type { ScheduleTemplate } from '@/hooks/useScheduleTemplates';
import {
  forwardRef,
  Grid,
  Icon,
  IconButton,
  MotionFlex,
  type MotionFlexProps,
  Text,
} from '@/ui';
import { TemplateCard } from './TemplateCard';

type Props = Omit<MotionFlexProps, 'onClick' | 'onSelect'> & {
  templates: ScheduleTemplate[];
  onReset: () => void;
  onSelect: (template: ScheduleTemplate) => void;
};

export const TemplateState = forwardRef<Props, typeof MotionFlex>(
  ({ templates, onReset, onSelect, ...props }, ref) => {
    return (
      <MotionFlex direction="column" flex="1" ref={ref} {...props}>
        <Grid
          alignItems="center"
          bg="white"
          pb="4"
          pt="5"
          px="6"
          templateColumns="32px minmax(0, auto) 32px"
        >
          <IconButton
            aria-label="Back"
            colorScheme="dark"
            icon={<Icon icon="ArrowLeft" size="6" />}
            justifySelf="flex-start"
            size="sm"
            variant="ghost"
            onClick={() => onReset()}
          />
          <Text fontWeight="medium" textAlign="center">
            📆 Select a template
          </Text>
        </Grid>

        <Grid
          gap="4"
          mx="6"
          my="6"
          templateColumns={{
            base: 'repeat(1, minmax(0, 1fr))',
            md: 'repeat(3, minmax(0, 1fr))',
          }}
        >
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              onClick={onSelect}
            />
          ))}
        </Grid>
      </MotionFlex>
    );
  }
);
