import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const SettingsModalTabs = ['account', 'help', 'calendars'] as const;

export type SettingsModalTab = (typeof SettingsModalTabs)[number];

export const isSettingsModalTab = (
  value: string
): value is SettingsModalTab => {
  return SettingsModalTabs.includes(value as SettingsModalTab);
};

type State = {
  isOpen: boolean;
  tab: SettingsModalTab;
  open: (tab?: SettingsModalTab) => void;
  close: () => void;
  changeTab: (tab: SettingsModalTab) => void;
};

export const useSettingsModal = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    tab: 'account',
    open: (tab: SettingsModalTab = 'account') => set({ tab, isOpen: true }),
    close: () => set({ isOpen: false }),
    changeTab: (tab: SettingsModalTab) => set({ tab }),
  }),
  shallow
);

export const useOpenSettingsModal = () => {
  const open = useSettingsModal((state) => state.open);
  return { openSettingsModal: open };
};
