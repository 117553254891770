import { useEffect, useRef, useState } from 'react';

// Keeps loading states visible for a minimum amount of time
export const useDebounceLoading = (isLoading: boolean, duration = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(isLoading);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isLoading) {
      setDebouncedValue(isLoading);
      return;
    }

    timer.current = setTimeout(() => {
      setDebouncedValue(isLoading);
    }, duration);

    return () => {
      clearTimeout(timer.current);
    };
  }, [isLoading, duration, setDebouncedValue]);

  return isLoading ? true : debouncedValue;
};
