import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { useAnalytics } from '@/hooks/useAnalytics';

type Defaults = {
  email: string;
};

type State = {
  isOpen: boolean;
  open: (args?: Partial<Defaults>) => void;
  close: () => void;
  defaults: Partial<Defaults>;
};

const useStore = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    defaults: {},
    open: (defaults: Partial<Defaults> = {}) => {
      set({
        defaults,
        isOpen: true,
      });
    },
    close: () => set({ isOpen: false }),
  }),
  shallow
);

type Selector = (state: State) => Partial<State>;

export const useShareModalStore = <
  S extends Selector,
  Ret = S extends undefined ? ReturnType<S> : State,
>(
  selector?: S
): Ret => {
  const { trackEvent } = useAnalytics();
  const state = useStore(selector || ((s) => s));
  if (!state.open) {
    return state as Ret;
  }
  return {
    ...state,
    open: (defaults: Partial<Defaults> = {}) => {
      trackEvent('schedule:share');
      state.open?.(defaults);
    },
  } as Ret;
};

export const useOpenShareModal = () => {
  const { open } = useShareModalStore((state) => ({ open: state.open }));
  return { openShareModal: open };
};
