import { lazy } from 'react';
import { i18n } from '@/i18n';
import { createViewModule } from '../utils';
import AgendaIcon from './agenda.svg?react';
import {
  adjustEndDate,
  adjustStartDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
} from './handlers';

const DateRangePicker = lazy(
  () => import('@/pages/Schedule/DateRangeNavigation/DateRangePicker')
);

export const AgendaModule = createViewModule({
  id: 'agenda',
  DateNavigationComponent: DateRangePicker,
  fetchMessagesWithEntries: true,
  containerProps: {
    bg: 'transparent',
  },
  adjustStartDate,
  adjustEndDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
  viewSelect: {
    label: i18n.t('actionBar:view_select.agenda'),
    Icon: AgendaIcon,
  },
});
