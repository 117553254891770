import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const helpers = createMultiStyleConfigHelpers(['list', 'item']);

export const Menu = helpers.defineMultiStyleConfig({
  baseStyle: (props) => ({
    item: {
      background: 'transparent',
      color: mode('inherit', 'gray.100')(props),
      _active: {
        background: mode('gray.200', 'whiteAlpha.400')(props),
      },
      _focus: {
        background: mode('gray.100', 'customgray.darker2')(props),
      },
      _hover: {
        background: mode('gray.100', 'customgray.darker2')(props),
      },
    },
    list: {
      background: mode('white', 'customgray.dark')(props),
    },
  }),
});
