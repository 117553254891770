export const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
    reader.onload = () => {
      if (!reader.result) {
        return reject(new Error('Failed to read file, result is null'));
      }

      const str = reader.result.toString();
      if (!str) {
        return reject(new Error('Failed to read file, result is empty'));
      }

      resolve(str);
    };
  });
};

export const isPDF = (file: unknown): file is File => {
  return file instanceof File && file.type === 'application/pdf';
};
