import pick from 'lodash.pick';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type CloseReason = 'cancel' | 'submit';

type CloseCallback = (closeReason: CloseReason) => void;

type State = {
  isOpen: boolean;
  open: (onClose?: CloseCallback) => void;
  close: (reason: CloseReason) => void;
  closeHandler?: CloseCallback;
};

const useMemberModalState = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    open: (onClose) => set({ closeHandler: onClose, isOpen: true }),
    close: (reason) =>
      set((state) => {
        state.closeHandler?.(reason);

        return {
          isOpen: false,
          closeHandler: undefined,
        };
      }),
  }),
  shallow
);

export const useMemberModal = () => {
  return useMemberModalState((state) =>
    pick(state, ['isOpen', 'open', 'close'])
  );
};
