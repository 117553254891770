import { datadogLogs } from '@datadog/browser-logs';
import { config } from '@/config';
import type { DecoratedProfile } from '@/contexts/CurrentUserContext';
import { getClientName } from '@/utils/getClientName';

if (config.datadog.clientToken) {
  datadogLogs.init({
    env: config.env,
    clientToken: config.datadog.clientToken,
    service: `client-${getClientName()}`,
    version: `${config.version}-${config.release}`,
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    silentMultipleInit: true,
    beforeSend: (event) => {
      const message = event.message.toLowerCase();

      if (
        message.includes('amplitude') ||
        message.includes('_ah_pixel_ratio_response')
      ) {
        return false;
      }
      return true;
    },
  });
}

export const setUser = (user: DecoratedProfile) => {
  if (!user) {
    return;
  }

  datadogLogs.setUserProperty('id', user.id);

  if (user.name) {
    datadogLogs.setUserProperty('name', user.name);
  }
  if (user.email) {
    datadogLogs.setUserProperty('email', user.email);
  }
};

export const logger = datadogLogs.logger;
