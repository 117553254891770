import { lazy } from 'react';
import { i18n } from '@/i18n';
import { AgendaModule } from '../AgendaView';
import { createViewModule } from '../utils';
import {
  adjustEndDate,
  adjustStartDate,
  adjustNextStartDate,
  adjustPreviousStartDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
  getVisibleStartDate,
} from './handlers';
import MonthIcon from './month.svg?react';

const MonthPicker = lazy(
  () => import('@/pages/Schedule/DateRangeNavigation/MonthPicker')
);

export const MonthModule = createViewModule({
  id: 'month',
  DateNavigationComponent: MonthPicker,
  mobileViewModule: AgendaModule,
  adjustStartDate,
  adjustEndDate,
  adjustNextStartDate,
  adjustPreviousStartDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
  getVisibleStartDate,
  viewSelect: {
    label: i18n.t('actionBar:view_select.month'),
    Icon: MonthIcon,
  },
});
