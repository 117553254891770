import type { DateTime } from 'luxon';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { i18n } from '@/i18n';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { EntryType } from '@/types/gql.generated';
import { createDraftEntryObject } from './utils';

export type DraftMode = 'create' | 'edit';

export type DraftComposer = 'modal' | 'inline';

type InitOptions = {
  launchAction?: 'create-message';
};

export type CreateDraftEntryOptions = InitOptions & {
  composer?: DraftComposer;
  touch?: boolean;
};

type DraftEntryState =
  | {
      draftMode: undefined;
      draftEntry: undefined;
      draftInstance: undefined;
      draftComposer: undefined;
      draftTouched: false;
      initOptions: undefined;
    }
  | {
      draftMode: 'create' | 'edit';
      draftEntry: DecoratedEntry;
      draftInstance: DecoratedInstance | undefined;
      draftComposer: DraftComposer;
      draftTouched: boolean;
      initOptions: InitOptions | undefined;
    };

type DraftEntryActions = {
  /**
   * Do not use this directly. Use the `useCreateDraftEntry` hook instead which
   * wraps it and provides critical functionality.
   */
  createDraftEntry: (
    date: DateTime,
    scheduleId: string,
    entryProps?: Partial<DecoratedEntry>,
    options?: CreateDraftEntryOptions
  ) => void;
  editDraftEntry: (
    entry: DecoratedEntry,
    instance?: DecoratedInstance,
    options?: InitOptions
  ) => void;
  updateDraftEntry: (updatedEntry: DecoratedEntry) => void;
  changeDraftEntryComposer: (composer: DraftComposer) => void;
  clearDraftEntry: () => void;
  cloneDraftEntry: (entryToClone: DecoratedEntry, scheduleId: string) => void;
  changeDraftMode: (mode: DraftMode) => void;
};

type State = DraftEntryState & DraftEntryActions;

const initialDraftState: DraftEntryState = {
  draftMode: undefined,
  draftComposer: undefined,
  draftEntry: undefined,
  draftInstance: undefined,
  draftTouched: false,
  initOptions: undefined,
};

export const useDraftEntryStore = createWithEqualityFn<State>()(
  (set) => ({
    ...initialDraftState,
    createDraftEntry: (date, scheduleId, entryProps, options) => {
      const entry = createDraftEntryObject(date, scheduleId, entryProps);

      set({
        draftMode: 'create',
        draftComposer: options?.composer ?? 'modal',
        draftEntry: entry,
        draftInstance: undefined,
        draftTouched: options?.touch ? true : false,
        initOptions: options,
      });
    },
    cloneDraftEntry: (entryToClone, scheduleId) => {
      const date = entryToClone.recurrences[0].startDate;

      const title = i18n.t('common:cloned_entry_title', {
        originalTitle: entryToClone.title,
      });

      const entry = {
        ...createDraftEntryObject(date, scheduleId, entryToClone),
        title,
        // Turn cloned feed items into schedule items
        type: EntryType.Schedule,
        feed: null,
      };
      set({
        draftMode: 'create',
        draftComposer: 'modal',
        draftEntry: entry,
        draftInstance: undefined,
        draftTouched: true,
      });
    },
    editDraftEntry: (entry, instance, options) => {
      set({
        draftMode: 'edit',
        draftComposer: 'modal',
        draftEntry: entry,
        draftInstance: instance,
        initOptions: options,
      });
    },
    updateDraftEntry: (updatedEntry) => {
      set({
        draftEntry: updatedEntry,
        draftTouched: true,
      });
    },
    changeDraftEntryComposer: (composer) => {
      set({
        draftComposer: composer,
        draftTouched: true,
      });
    },
    clearDraftEntry: () => {
      set(initialDraftState);
    },
    changeDraftMode: (draftMode) => {
      set({ draftMode });
    },
  }),
  shallow
);
