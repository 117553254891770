import { css, Global } from '@emotion/react';
import { Picker, type PickerProps } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { useEffect } from 'react';
import { useColorMode, useColorModeValue, useToken } from '@/ui';

// The emoji-mart lib has an `autoFocus` attribute that's supposed to focus
// the search field on open, but it doesn't work when rendered inside a
// Chakra popover. Chakra exposes `initialFocusRef` on `Popover` to workaround
// this, but emoji-mart doesn't expose an API to access the search input ref.
// So we do it the old fashion way via imperative DOM methods.
const useAutoFocusSearchField = () => {
  useEffect(() => {
    const timerId = setTimeout(() => {
      const input = document.querySelector('.emoji-mart-search input') as
        | HTMLInputElement
        | undefined;
      input?.focus();
    });

    return () => clearTimeout(timerId);
  }, []);
};

export const EmojiSelect = ({ style, ...props }: PickerProps) => {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue('white', 'customgray.darker2');
  const bgValue = useToken('colors', bgColor);
  useAutoFocusSearchField();

  return (
    <>
      <Global
        styles={css`
          .emoji-mart {
            border: none;
          }
          .emoji-mart-dark .emoji-mart-category-label span {
            background-color: ${bgValue};
          }
        `}
      />
      <Picker
        perLine={6}
        theme={colorMode}
        title=""
        style={{
          width: 300,
          fontFamily: 'inherit',
          background: bgValue,
          ...style,
        }}
        {...props}
      />
    </>
  );
};
