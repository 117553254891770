import { useEffect } from 'react';
import { CAMPAIGN_SESSION_STORAGE_KEY } from '../constants';
import { useQueryParams } from './useQueryParams';

export const useCampaignTracking = () => {
  const { queryParams } = useQueryParams();

  useEffect(() => {
    const campaign = queryParams.get('campaign');
    if (campaign) {
      sessionStorage.setItem(CAMPAIGN_SESSION_STORAGE_KEY, campaign);
    }
  }, [queryParams]);
};
