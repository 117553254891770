import { DateTime } from 'luxon';

export const isToday = (date: DateTime, timeZone: string): boolean => {
  return date.toISODate() === DateTime.local({ zone: timeZone }).toISODate();
};

export const isMidnight = (date: DateTime): boolean => {
  return date.hour === 0 && date.minute === 0 && date.second === 0;
};

export const isAllDay = (startDate: DateTime, endDate: DateTime): boolean => {
  return (
    isMidnight(startDate) &&
    isMidnight(endDate) &&
    endDate.equals(startDate.plus({ days: 1 }))
  );
};

export const isMultiDay = (startDate: DateTime, endDate: DateTime): boolean => {
  if (+startDate === +endDate) {
    return false;
  }
  return isMidnight(endDate)
    ? !areDaysEqual(startDate, endDate.minus({ days: 1 }))
    : !areDaysEqual(startDate, endDate);
};

export const applyTimeToDate = (
  dateReceivingTime: DateTime,
  dateWithTimeToApply: DateTime
): DateTime => {
  return dateReceivingTime.set({
    hour: dateWithTimeToApply.hour,
    minute: dateWithTimeToApply.minute,
    second: dateWithTimeToApply.second,
  });
};

/**
 * Get a DateTime that represents the start of the week.
 *
 * Note:
 * - Luxon hard-codes the start of the week to Monday
 * - Luxon doesn't respect locale settings so neither do we (yet).
 *   This logic always returns a DateTime on Sunday.
 */
export const getStartOfWeek = (date: DateTime): DateTime => {
  return date.weekday === 7
    ? date.startOf('day')
    : date.startOf('week').minus({ day: 1 });
};

/**
 * Parse an ISO-8601 date in the specified zone.
 */
export const dateInZone = (
  date: string,
  authoredTimeZone: string,
  scheduleTimeZone: string
): DateTime => {
  return DateTime.fromISO(date, { zone: authoredTimeZone }).setZone(
    scheduleTimeZone
  );
};

export const areDaysEqual = (date1: DateTime, date2: DateTime): boolean => {
  return +date1.startOf('day') === +date2.startOf('day');
};

export const areDatesSame = (date1: DateTime, date2: DateTime): boolean => {
  return date1.toMillis() === date2.toMillis();
};

export const areTimesInSameMeridian = (
  date1: DateTime,
  date2: DateTime
): boolean => {
  return date1.hour < 12 === date2.hour < 12;
};

export const datePartInZone = (
  date: DateTime | string,
  zone: string
): DateTime => {
  if (typeof date === 'string') {
    date = DateTime.fromISO(date);
  }
  return DateTime.fromISO(date.toISODate(), { zone });
};

export const getLocalTimeZone = (): string => {
  return DateTime.local().zoneName;
};

export const getDateCardinal = (day: number) => {
  if (day > 3 && day < 21) {
    return 'th';
  }

  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const isDateOutsideRange = (
  date: DateTime,
  range: { startDate: DateTime; endDate: DateTime }
): boolean => {
  const startOfDate = date.startOf('day');
  return (
    startOfDate < range.startDate.startOf('day') ||
    startOfDate > range.endDate.startOf('day')
  );
};

// Chrome and Firefox default the year to 2001 if a year cannot be determined
// from the user's input. new Date('2/1') returns 2001-02-01. In this case,
// default it to the current year instead.
// https://github.com/Hacker0x01/react-datepicker/issues/3586
export const maybeAdjustDefaultYear = (date: DateTime): DateTime => {
  return date.year === 2001 ? date.set({ year: DateTime.local().year }) : date;
};

export const createEntryDate = (
  date: string,
  authoredTimeZone: string,
  scheduleTimeZone: string,
  isOnDay: boolean
): DateTime => {
  // Special handling for all day events because the `startDate` for these
  // events are stored with time (midnight UTC) and creating a `DateTime`
  // with a time part will cause the date to shift. Removing the time part
  // allows the `DateTime` to be created in the right zone without time
  // influencing the date. Longer term, we should store the start/end date
  // for all day items without time which is what Google does.
  if (isOnDay) {
    return DateTime.fromISO(date.replace(/T.*$/, ''), {
      zone: scheduleTimeZone,
    });
  }

  return dateInZone(date, authoredTimeZone, scheduleTimeZone);
};

export const getRelativeYears = (
  date: DateTime,
  before: number,
  after: number
): number[] => {
  const base = date.year;
  const start = base - before;
  const end = base + after;

  return new Array(end - start + 1).fill(0).map((_, i) => start + i);
};
