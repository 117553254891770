export const Checkbox = {
  baseStyle: {
    control: {
      borderRadius: '2px',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
};
