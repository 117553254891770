import type { CreateScheduleMutationVariables } from '@/hooks/useCreateSchedule/useCreateSchedule.generated';
import type { ScheduleOption } from './types';

export const getVariablesForOption = (
  option: ScheduleOption
): Partial<CreateScheduleMutationVariables> => {
  if (option === 'family') {
    return {
      title: 'Our Family Calendar',
      headerEnabled: false,
    };
  }

  return {};
};
