import { useEffect, useRef } from 'react';

export const useTimeoutEffect: typeof useEffect = (fn, deps, delay = 5000) => {
  const callback = useRef(fn);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    const timerId = setTimeout(callback.current, delay);
    return () => clearTimeout(timerId);
    // eslint-disable-next-line
  }, deps);
};
