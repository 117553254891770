import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useToast } from '@/ui';

const CODE_PARAM = 'code';

export const useServerCodeToast = () => {
  const { t } = useTranslation('auth');
  const toast = useToast();
  const { queryParams } = useQueryParams();
  const code = queryParams.get(CODE_PARAM);
  const hasProcessedToast = useRef(false);

  useEffect(() => {
    if (!hasProcessedToast.current && code) {
      toast.error(t('errors.server_code.unknown'));
      hasProcessedToast.current = true;
    }
  }, [t, toast, code]);
};
