import { AnimatePresence } from 'framer-motion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useOpenRegisterModal } from '@/components/RegisterModal';
import { useCurrentUserContext } from '@/contexts';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useLauncherAction } from '@/hooks/useLauncher';
import { SchedulesWithCustomCTA } from '@/pages/Schedule/constants/custom-ctas';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  useToggleScheduleSave,
  useTimeoutEffect,
} from '@/pages/Schedule/hooks';
import {
  MotionFlex,
  type MotionFlexProps,
  useToast,
  Button,
  forwardRef,
  useDisclosure,
} from '@/ui';
import { SuccessState } from './SuccessState';

export const FollowScheduleButton = (props: MotionFlexProps) => {
  const { t } = useTranslation('schedule', { keyPrefix: 'follow_schedule' });
  const { trackEvent } = useAnalytics();
  const { scheduleId, schedule } = useScheduleContext();
  const { toggleScheduleSave, isPending, isSuccess, reset } =
    useToggleScheduleSave();
  const { isAuthenticated } = useCurrentUserContext();
  const { openRegisterModal } = useOpenRegisterModal();
  const toast = useToast();
  const isSaved = schedule?.isSaved ?? false;

  const {
    isOpen: isConfirmUnfollowOpen,
    onOpen: openConfirmUnfollow,
    onClose: closeConfirmUnfollow,
  } = useDisclosure();

  const fireMutation = (clickContext: { didAuth: boolean }) => {
    if (clickContext.didAuth) {
      trackEvent('schedule:cta follow-schedule success', {
        scheduleId,
      });
    }

    const nextValue = !isSaved;

    toggleScheduleSave(scheduleId, nextValue, {
      onError: () => {
        toast.error(
          nextValue ? t('follow_error_toast') : t('unfollow_error_toast')
        );
      },
      onSuccess: () => {
        if (!nextValue) {
          toast.notify(t('unfollow_success_toast'));
        }
      },
    });
  };

  const handleFollowClick = () => {
    trackEvent('schedule:click follow-schedule', {
      scheduleId,
      isAuthenticated,
    });

    if (isAuthenticated) {
      fireMutation({ didAuth: false });
      return;
    }

    openRegisterModal({
      action: 'follow_schedule',
      launch: 'follow-schedule',
      onAuthenticated: () => fireMutation({ didAuth: true }),
    });
  };

  const handleUnfollowConfirm = () => {
    fireMutation({ didAuth: false });
    closeConfirmUnfollow();

    trackEvent('schedule:click unfollow-schedule', {
      scheduleId,
    });
  };

  useTimeoutEffect(() => {
    // return the mutation to its default state after a short timeout to clear the success state
    if (isSaved && isSuccess) {
      reset();
    }
  }, [isSaved, isSuccess, reset]);

  useLauncherAction('follow-schedule', () => {
    if (!schedule?.isSaved) {
      fireMutation({ didAuth: true });
    }
  });

  const showFollowState = !isSaved || isPending;
  const showSuccessState = isSaved && isSuccess;
  const showUnfollowState = !showFollowState && !showSuccessState;

  const isSecondaryCta = useMemo(() => {
    const scheduleIdsWithCTA: string[] = Object.values(SchedulesWithCustomCTA);
    return scheduleIdsWithCTA.includes(scheduleId);
  }, [scheduleId]);
  const scheduleIdsWithTeritaryCta: string[] = [
    SchedulesWithCustomCTA.BEAUTY_AND_BEAST_PLAY,
    SchedulesWithCustomCTA.SAN_DIMAS_STARS,
    SchedulesWithCustomCTA.SAN_DIMAS_GATORS,
    SchedulesWithCustomCTA.ELF_MAINSTAGE_FAMILY_SCHEDULE,
    SchedulesWithCustomCTA.ELAINE_GIRL_SCOUT_TROOP,
  ];
  const isTertiaryCta = scheduleIdsWithTeritaryCta.includes(scheduleId);

  return (
    <>
      <ConfirmDialog
        confirmButtonText={t('unfollow_confirm.confirm')}
        isLoading={isPending}
        isOpen={isConfirmUnfollowOpen}
        message={t('unfollow_confirm.message')}
        title={t('unfollow_confirm.title')}
        onCancel={closeConfirmUnfollow}
        onConfirm={handleUnfollowConfirm}
      />

      <AnimatePresence mode="popLayout">
        {showSuccessState && (
          <MotionFlexContainer key="followed" zIndex="1" {...props}>
            <SuccessState scheduleId={scheduleId} />
          </MotionFlexContainer>
        )}

        {showUnfollowState && (
          <MotionFlexContainer key="unfollow" {...props}>
            <Button
              colorScheme="dark"
              variant="link"
              onClick={() => openConfirmUnfollow()}
            >
              {t('unfollow_schedule')}
            </Button>
          </MotionFlexContainer>
        )}

        {showFollowState && (
          <MotionFlexContainer key="default" {...props}>
            <Button
              colorScheme={isSecondaryCta ? 'yellow' : undefined}
              isLoading={isPending}
              variant={
                isTertiaryCta
                  ? 'secondary'
                  : isSecondaryCta
                    ? 'outline'
                    : undefined
              }
              onClick={handleFollowClick}
            >
              {t('follow_schedule')}
            </Button>
          </MotionFlexContainer>
        )}
      </AnimatePresence>
    </>
  );
};

const MotionFlexContainer = forwardRef((props: MotionFlexProps, ref) => (
  <MotionFlex
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
    ref={ref}
    {...props}
  />
));
