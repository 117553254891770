import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  DeleteQuickEntryMutation,
  DeleteQuickEntryMutationVariables,
} from './useDeleteQuickEntry.generated';

const query = gql`
  mutation DeleteQuickEntry($quickEntriesJobId: ID!, $quickEntryId: ID!) {
    deleteQuickEntry(
      quickEntriesJobId: $quickEntriesJobId
      quickEntryId: $quickEntryId
    )
  }
`;

export const useDeleteQuickEntry = () => {
  const { mutate, isPending, reset } = useMutation<
    DeleteQuickEntryMutation,
    QueryError,
    DeleteQuickEntryMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        DeleteQuickEntryMutation,
        DeleteQuickEntryMutationVariables
      >(query, variables),
  });

  const deleteQuickEntry = useCallback(
    (
      quickEntriesJobId: string,
      quickEntryId: string,
      options?: MutateOptions<
        DeleteQuickEntryMutation,
        QueryError,
        DeleteQuickEntryMutationVariables
      >
    ) => mutate({ quickEntriesJobId, quickEntryId }, options),
    [mutate]
  );

  return {
    deleteQuickEntry,
    isPending,
    reset,
  };
};
