import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { logger } from '@/lib/logger';
import { QuickEntriesJobVersion } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  CreateQuickEntriesJobFromPromptMutation,
  CreateQuickEntriesJobFromPromptMutationVariables,
} from './useCreateQuickEntriesJobFromPrompt.generated';

const query = gql`
  mutation CreateQuickEntriesJobFromPrompt(
    $prompt: String!
    $timeZone: TimeZone!
    $version: QuickEntriesJobVersion!
  ) {
    createQuickEntriesJobFromPrompt(
      prompt: $prompt
      timeZone: $timeZone
      version: $version
    ) {
      id
    }
  }
`;

export const useCreateQuickEntriesJobFromPrompt = () => {
  return useMutation<
    string,
    QueryError,
    Omit<CreateQuickEntriesJobFromPromptMutationVariables, 'version'>
  >({
    mutationFn: async (variables) => {
      logger.info('Calling createQuickEntriesJobFromPrompt mutation', {
        variables,
      });

      const result = await gqlClient.request<
        CreateQuickEntriesJobFromPromptMutation,
        CreateQuickEntriesJobFromPromptMutationVariables
      >(query, {
        ...variables,
        version: QuickEntriesJobVersion.V2,
      });

      return result.createQuickEntriesJobFromPrompt.id;
    },
  });
};
