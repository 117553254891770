import { config as audubonConfig } from './audubon';

export type PageExperienceConfig = {
  slug: string;
  entries?: {
    hidePastEntries: boolean;
  };
  theme: {
    page: {
      backgroundColor: string;
    };
    colors: {
      primary: string;
    };
  };
  header?: {
    href: string;
    banner: string;
    logo: string;
  };
  subscribe?: {
    google: string;
    outlook: string;
    apple: string;
    other: string;
  };
};

export const DEFAULT_CONFIG: PageExperienceConfig = {
  slug: '',
  theme: {
    page: {
      backgroundColor: '#edf3f4',
    },
    colors: {
      primary: '#2483d0',
    },
  },
};

export const configs = {
  [audubonConfig.slug]: audubonConfig,
} as const satisfies Record<string, PageExperienceConfig>;

export type PageExperienceSlug = keyof typeof configs;

export type ConfigKey = PageExperienceSlug | 'default';

export const PAGE_EXPERIENCE_SLUGS = Object.keys(
  configs
) as PageExperienceSlug[];

export const getConfig = (key: ConfigKey = 'default'): PageExperienceConfig =>
  key === 'default' ? DEFAULT_CONFIG : (configs[key] ?? DEFAULT_CONFIG);
