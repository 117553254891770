import { INITIAL_PROMPT } from '../constants';
import type { Prompt } from '../types';

export const isPromptLike = (prompt: unknown): prompt is Partial<Prompt> => {
  return (
    !!prompt &&
    typeof prompt === 'object' &&
    (('html' in prompt && 'text' in prompt) ||
      'base64' in prompt ||
      'file' in prompt)
  );
};

export const ensurePrompt = (prompt: Partial<Prompt>): Prompt => ({
  ...INITIAL_PROMPT,
  ...prompt,
});

export const hasTextPrompt = (prompt: Prompt): boolean => {
  return prompt.html.length > 0 && prompt.text.length > 0;
};

export const hasFilePrompt = (prompt: Prompt): boolean => {
  return (
    prompt.file !== null ||
    (typeof prompt.base64 === 'string' && prompt.base64.length > 0)
  );
};

export const isPromptValid = (prompt: Prompt): boolean => {
  return hasTextPrompt(prompt) || hasFilePrompt(prompt);
};

export const cleanPrompt = (prompt: Prompt): Prompt => {
  const clean = (str: string): string => {
    return (
      str
        .trim()
        // replace all whitespace characters with a single space
        // see https://linear.app/agendhero/issue/AH-1302/unexpected-magic-results
        .replace(/\s/gu, ' ')
    );
  };
  return {
    ...prompt,
    text: clean(prompt.text),
    html: clean(prompt.html),
  };
};

export const areTextPromptsEqual = (a: Prompt, b: Prompt): boolean => {
  const cleanA = cleanPrompt(a);
  const cleanB = cleanPrompt(b);
  return cleanA.text === cleanB.text && cleanA.html === cleanB.html;
};
